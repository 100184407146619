import * as React from "react";
import { 
    BooleanField,
    ReferenceField,
    FunctionField,
    ArrayField,
    DateField,
    SingleFieldList,
    TextField,
    ChipField,
    Filter,
    Datagrid,
    List,
    linkToRecord,
    SelectInput,
    TextInput,
    DatagridBody
   } from 'react-admin';
import { Link } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

const CustomRow = ({record, resource, id, onToggleItem, children, selected, selectable, basePath, resourceType}) => {
  const linkToGame = linkToRecord(`/${resourceType}`, record.id, 'show');
  return  <TableRow key={id} component={Link} to={linkToGame} style={{textDecoration: 'none'}}>
        <TableCell padding="none">
            <Checkbox
                disabled={selectable}
                checked={selected}
                onClick={event => onToggleItem(id, event)}
            />
        </TableCell>
        {React.Children.map(children, field => (
            <TableCell key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                })}
            </TableCell>
        ))}
    </TableRow>
}

const CustomDataGridBody = props => <DatagridBody {...props} row={<CustomRow {...props}/>} />;
// const CustomRecordLink = ({ record }) => {
//   const linkToGame = linkToRecord('/game', record.id, 'show');
//   return <Link to={linkToGame} style={{textDecoration: 'none'}}>
//     <IconButton color="primary" aria-label="upload picture" component="span">
//           <VisibilityIcon />
//         </IconButton>
//   </Link>
// }

export default CustomDataGridBody;