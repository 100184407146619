import * as React from "react";
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    bottom: 0
    // position: "fixed" // remove this so we can apply flex design
  },
  bubbleContainer: {
    width: "100%",
    display: "flex" //new added flex so we can put div at left and right side
    //check style.css for left and right classnaeme based on your data
  },
  bubble: {
    //border: "0.5px solid black",
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: "5px 0",
    padding: "10px",
    display: "inline-block"
  },
  bubbleSecondary: {
    //border: "0.5px solid black",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    margin: "5px 0",
    padding: "10px",
    display: "inline-block"
  },  
  right: {
    justifyContent: "flex-end !important",
  },
  left: {
    justifyContent: "flex-start !important",
  }  
})); 

const Message = ({ source, record = {}, ...rest }) => { 
  ///console.log(rest);
  const { game } = rest;
  const classes = useStyles();

  const isFirstUser = game.users[0].data._id === record.sender;  
  //const bgColor = 'primary'
  const player = game.users.find(u => u.data._id === record.sender);

  if (record.sysMessage) {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems='center'
        key={record._id}>     
        <span>{player? player.data.name : ''} {record.body}</span>
      </Grid>
    )
  } else {    
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems={isFirstUser ? 'flex-start': 'flex-end'}
        key={record._id}>      
        <span>{player.data.name} {record.body}</span> 
        <span>{record.createdAt}</span>
        <div>
          {record.pmGroup.map((item, index) => (
            <div 
              className={`${classes.bubbleContainer} ${isFirstUser ? classes.left: classes.right}`} key={index}>              
              <div key={index++} className={isFirstUser ? classes.bubble: classes.bubbleSecondary}>
                <div className={classes.button}>{item.pm}</div>
              </div>
            </div>          
          ))}
        </div>  
      </Grid>
    )
  }
}

Message.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default Message;