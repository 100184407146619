import React from "react";
import {
  ReferenceInput,
  AutocompleteInput
} from "react-admin";

const CourtFilter = props => {  
    const filterToQuery = searchText => ({ search: searchText });
    return (
      <ReferenceInput {...props} reference="Court" filterToQuery={filterToQuery}>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    );
}

export default CourtFilter;