import * as React from "react";
import { 
    SimpleForm,
    ReferenceArrayInput,
    TextInput,
    Edit,
    TabbedForm,
    DateInput,
    Toolbar,
    TextField,
    NumberInput,
    BooleanInput,
    SaveButton
   } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteUserButton from "../DeleteUserButton";
import { Card as MUCard, CardHeader } from "@material-ui/core";
import SuspendUserButton from "../components/SuspendUserButton";
import BlockUserButton from "../components/BlockUserButton";

const Card = (props) => (
  <MUCard style={{ padding: 10, width: "auto" }} {...props} />
);

const CustomUserToolbar = props => {
  const { record } = props;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <BlockUserButton/>
      <SuspendUserButton/>
      { !record.deleted && <DeleteUserButton/>}
    </Toolbar>
  );
};

const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },    
});
export const UserEdit = (props) => (
  <Edit {...props}>
      <SimpleForm toolbar={<CustomUserToolbar />}>
          <TextInput disabled label="Id" source="id" />    
          <TextInput source="name" />
          <TextInput source="firstName" />
          <TextInput source="lastName" />          
          <TextInput source="avatar" />        
          <TextInput source="email" />                 
          <TextInput source="mobile" />
          <TextField label="Location" source="location.text" />
          <NumberInput label="Skill" source="skillLevel" />
          <NumberInput label="League Entry Skill" source="leagueEntrySkillLevel" />
          {/* <DateField source="lastOnline" showTime={true} options={timeOptions} locales="en-GB" /> */}
          <BooleanInput label="Hidden" source="hidden"/>
         <Card variant="outlined">
            <CardHeader title="Membership" />
            <DateInput source="membership.end" label="Membership End"/>
         </Card>
          <Card variant="outlined">
            <CardHeader title="Notification Settings" />
            <Card variant="outlined">
              <CardHeader subheader="Messages" />
              <BooleanInput source="notificationSettings.messages.email" label="Email"/>
              <BooleanInput source="notificationSettings.messages.push" label="Push"/>
              <BooleanInput source="notificationSettings.messages.required" label="Required"/>
            </Card>
            <Card variant="outlined">
              <CardHeader subheader="Nearby" />
              <BooleanInput source="notificationSettings.nearby.email" label="Email"/>
              <BooleanInput source="notificationSettings.nearby.push" label="Push"/>
              <BooleanInput source="notificationSettings.nearby.required" label="Required"/>
            </Card>
            <BooleanInput source="notificationSettings.unsubscribe" label="Unsubscribe"/>
          </Card>
          <Card variant="outlined">
            <CardHeader title="Notes" />
            <TextInput label="User Notes" source="notes" multiline fullWidth resettable/>
          </Card>
          {/* <BooleanInput label="Boarded" source="boarded"/>             */}
          {/* <TextInput disabled label="Version" source="appInfo.version" />
          <TextInput disabled label="Device" source="appInfo.deviceModel" />
          <TextInput disabled label="Device Name" source="appInfo.deviceName" />
          <TextInput disabled label="Push" source="appInfo.pushPermissions" /> */}
          {/* <JsonInput
            source="notificationSettings"
            // validate={[required()]}
            jsonString={false} // Set to true if the value is a string, default: false
            reactJsonOptions={{
              // Props passed to react-json-view
              name: null,
              collapsed: true,
              enableClipboard: false,
              displayDataTypes: false,
            }}
          />           */}
          {/* <TextInput multiline source="teaser" validate={required()} /> */}
          {/* <RichTextInput source="body" validate={required()} /> */}
          {/* <DateInput label="Publication date" source="published_at" /> */}
          {/* <ReferenceManyField label="Comments" reference="comments" target="post_id">
              <Datagrid>
                  <TextField source="body" />
                  <DateField source="created_at" />
                  <EditButton />
              </Datagrid>
          </ReferenceManyField> */}
      </SimpleForm>
  </Edit>
);
  
  export default UserEdit;
  