import { gql } from '@apollo/client';
const GEOGRAPHIC_AREA_CHILDREN = gql`
  query geographicAreaChildren($id: String!) {
    geographicAreaChildren(id: $id) 
    #{
     #   _id
      #  geometry
       # name
    #}
  }
`;
export default GEOGRAPHIC_AREA_CHILDREN;