import * as React from "react";
import {
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  Create,
  SelectInput,
  required
} from "react-admin";
import { JsonField } from "react-admin-json-view";
import { Card as MUCard, CardHeader, CardContent } from "@material-ui/core";
import { timeOptions } from "../constants";
import { Autocomplete } from "@react-google-maps/api";
import { makeStyles } from '@material-ui/core/styles';
import MapGeoJSONExtended from "../components/GoogleMaps/MapGeoJSONExtended";

const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },    
});

const Card = (props) => (
  <MUCard style={{ padding: 10, width: "auto" }} {...props} />
);

const GeographicAreaCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        {/* <NumberInput source="level"/> */}
         <TextInput source="slug" />
         <ReferenceInput label="Parent" source="parent" reference="GeographicArea" filterToQuery={searchText => ({ search: searchText })}>
            <AutocompleteInput optionText={(rec) => {
              return `${rec.name.en_GB} (${rec.slug})`;
            }} />
          </ReferenceInput>
          <SelectInput source="type" choices={['COUNTRY', 'CITY', 'STATE', 'PLACE'].map(s => ({id: s, name: s}))} validate={required()} />  
          <Card variant="outlined">
            {/* <CardHeader title="Name" /> */}
            <CardHeader subheader="Name" />
            <Card variant="outlined">
              <TextInput source="name.en_GB" label="en_GB"/>
              <TextInput source="name.en_US" label="en_US"/>
              <TextInput source="name.es" label="es"/>
            </Card>
          </Card>
        <MapGeoJSONExtended source="geometry"/>
      </SimpleForm>
    </Create>
  );
};

export default GeographicAreaCreate;
