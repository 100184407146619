import React, { useEffect } from "react";
import {
    Datagrid,
    TextField,
    ReferenceManyField,
    TabbedShowLayout,
    Tab,
    ShowController,
    ShowView,
    ReferenceArrayField
} from 'react-admin';
import { useLocation } from "react-router-dom";
import ChatMessage from "../chatMessage";
import CreateAdminEventLogButton from "../CreateAdminEventLogButton";
import ChatBlockSettingsButton from "../components/ChatBlockSettingsButton";

const ChatShow = props => {

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        setTimeout(() => {
            const targetPlayer = document.getElementById(hash.substr(1));
            if (targetPlayer) {
                targetPlayer.scrollIntoView({ behavior: 'smooth' });
            }
        }, 3000);
    }, [location.hash]);

    return (
        <ShowController {...props}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps}>
                    <TabbedShowLayout>
                        <Tab label="Messages">
                            <ReferenceManyField
                                reference="ChatMessage"
                                target="chatId"
                                label="Messages"
                                sort={{ field: 'createdAt', order: 'ASC' }}
                            >
                                <Datagrid>
                                    <ChatMessage label="Messages" chat={controllerProps.record} source="createdAt" {...props} />
                                </Datagrid>
                            </ReferenceManyField>
                        </Tab>
                        <Tab label="summary">
                            <TextField label="Id" source="id" />
                            {/* <ReferenceManyField
                  reference="EventLog"
                  target="playerIds"
                  source="playerIds"
                  label="Played Events"
                > 
                  <Datagrid>
                    <TextField source="type" />
                  </Datagrid>
                </ReferenceManyField>             */}
                            <CreateAdminEventLogButton />
                        </Tab>
                        <Tab label="chat block settings">
                            <ReferenceArrayField
                                label="Players"
                                reference="User"
                                source="playerIds"
                            >
                                <Datagrid>
                                    <TextField source="name" />
                                    <ChatBlockSettingsButton chat={controllerProps.record} />
                                </Datagrid>
                            </ReferenceArrayField>
                        </Tab>
                    </TabbedShowLayout>
                </ShowView>
            }
        </ShowController>
    );
};

export default ChatShow;