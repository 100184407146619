import { gql } from '@apollo/client';
const RECALCULATE_STANDINGS = gql`
  mutation gameRecalculateStanding($id: String!) {
    gameRecalculateStanding(id: $id) {
        _id
        startTime
        status
    }
  }
`;
export default RECALCULATE_STANDINGS;