// import { gameStatus } from './constants';
import isSetWinner from './isSetWinner';

export const getMatchWinnerId = game => {
  // if (game.status === gameStatu.UNFINISHED) {
  //   return null;
  // }
  if(!game.sets || game.sets.length === 0) {
    return false;
  }
  let startingIndex = game.score.sets.length - 1;
  let lastSet = game.score.sets[startingIndex];
  // find the last played set
  while (typeof lastSet[0] !== 'number' || typeof lastSet[1] !== 'number') {
    lastSet = game.score.sets[--startingIndex];
  }
  // whoever won the last played set, won the match
  return isSetWinner(game, game.users[0].data._id, lastSet, startingIndex)
    ? game.users[0].data._id
    : game.users[1].data._id;
};
