export const formatScore = (game, keepOrder) => {
  const sets = game.score.sets || []
  const tbs = game.score.tbs || [];
  const getTbVal = (tb, set) => {    
    if (tb[0] != null) {
      return set[0] === 7 ? `(${tb[1]})` : `(${tb[0]})`;
    }
    return '';
  }
  return sets.map((set, i) => {
    let currentTBS = tbs[i] ? [...tbs[i]] : [];
    let currentSet = set ? [...set] : [];
    if (!keepOrder) {
      currentTBS && currentTBS.reverse();
      currentSet.reverse();
    }
    if(currentSet.length === 0 || !currentSet[0] && !currentSet[1]) return null
    return i < 2 ? `${currentSet[0]}-${currentSet[1]}${getTbVal(currentTBS, currentSet)}` : `[${currentSet[0]}-${currentSet[1]}]`
  }).join(" ");
}