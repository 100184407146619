import * as React from "react";
import { 
    BooleanField,
    SingleFieldList,
    NumberField,
    ArrayField,
    Datagrid,
    ImageField,
    DateField,
    FunctionField,
    TextField,
    ReferenceArrayField,
    ReferenceField,
    Tab,
    TabbedShowLayout,
    ShowView,
    ShowController,
    useRefresh
   } from 'react-admin';
import { timeOptions } from "../constants";
import { formatScore } from "../utils";

const PlayerProgressLogShow = props => {
  return <ShowController {...props}>
    {controllerProps =>
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>         
          <Tab label="Record">
          <ReferenceField link="show" label="Player" source="userId" reference="User">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField link="show" label="Season" source="seasonId" reference="Season">
            <FunctionField
              label="Season"
              render={record => `${record.league.name} - ${record.name}`}
            />
          </ReferenceField>
          <TextField source="division"/>
          <NumberField source="averageSkillPlayed" label="Average Skill Played" />
          <NumberField source="averageRatingPlayed" label="Average Rating Played" />
          <NumberField source="percentageOfGamesWon" label="Percentage Games Won" options={{ style: 'percent' }} />
          <NumberField source="previousValue" label="Skill" />
          <NumberField source="newValue" label="Suggested Skill" />          
          {/* <DateField label="Created" source="createdAt" showTime={true} options={timeOptions} locales="en-GB" /> */}              
          </Tab>
          <Tab label="Games">          
            <ArrayField label="Games" source="gamesData">
                <Datagrid>
                  <ArrayField label="Players" source="game.users">
                      <Datagrid>
                          <TextField source="data.name" label="Name" />
                          <NumberField source="data.leagueEntrySkillLevel" label="Skill" />
                          <NumberField source="data.spinRating" label="Rating" />
                          <FunctionField
                            label="Skill From Rating"
                            render={record => ((record.data.spinRating/750) - 1).toFixed(2)}
                          />                          
                          <BooleanField source="data.isLeagueVerified" label="Verified" />
                      </Datagrid>
                  </ArrayField>
                  <FunctionField
                      label="Score"
                      render={record => formatScore(record.game, true)}
                  />                
                  <NumberField source="percentageOfGamesWon" label="Percentage Games Won" options={{ style: 'percent' }} />
                </Datagrid>
            </ArrayField>                       
            {/* <ReferenceArrayField label="Games" reference="Game" source="gamesData.gameId">
                <Datagrid>
                  <ArrayField label="Players" source="users">
                      <Datagrid>
                          <TextField source="data.name" label="Name" />
                          <NumberField source="data.leagueEntrySkillLevel" label="Skill" />
                          <NumberField source="data.spinRating" label="Rating" />
                          <BooleanField source="data.isLeagueVerified" label="Verified" />
                      </Datagrid>
                  </ArrayField>
                  <FunctionField
                      label="Score"
                      render={record => formatScore(record, true)}
                  />                
                </Datagrid>
            </ReferenceArrayField>              */}

          </Tab>           
        </TabbedShowLayout>
      </ShowView>
    }
  </ShowController>
}
  
export default PlayerProgressLogShow;
  