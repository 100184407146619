import * as React from "react";
import {
    TextField,
    FunctionField,
    ReferenceField,
    Labeled
} from 'react-admin';


const PaymentUser = props => {
    const record = props?.record ?? {};
    const { userId, firstName, lastName } = record;

    if (!userId) return (
        <FunctionField
            {...props}
            render={record => `${firstName} ${lastName}`}
        />
    );

    return (
        <ReferenceField {...props} source="userId" reference="User">
            <TextField source="name" />
        </ReferenceField>
    );

}

export default PaymentUser