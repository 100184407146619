// import PeopleIcon from '@material-ui/icons/People';
// import SeasonCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserList from './UserList';
import UserShow from './UserShow';
// import UserList from './UserList';
// import SeasonShow from './UserShow';

export default {
    list: UserList,
    // create: UserCreate,
    edit: UserEdit,
    show: UserShow,
    // icon: PeopleIcon,
};