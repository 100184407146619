import CouponList from './CouponList';
import CouponEdit from './CouponEdit';
import CouponCreate from './CouponCreate';
// import UserList from './UserList';
// import SeasonShow from './UserShow';

export default {
    list: CouponList,
    create: CouponCreate,
    edit: CouponEdit,
    // show: CouponShow,
    // icon: PeopleIcon,
};