import React from "react";
import { useFormState } from 'react-final-form';
import { SelectInput, useQuery } from "react-admin";

const SeasonSelect = props => {
    
    const { values } = useFormState();

    const { data: seasonData, loading: seasonFilterLoading, error: seasonFilterError } = useQuery({ 
      type: 'getList',
      resource: 'Season',
      payload: { filter: { league: values?.leagueId }, pagination: { page: 1, perPage: 100 } }
    });  
  
    return (
        <SelectInput
            choices={seasonData}
            isLoading={seasonFilterLoading}
            {...props}
            optionText="name"
            optionValue="_id"
        />        
    )
  }

  export default SeasonSelect;