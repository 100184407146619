import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { Button as AdminButton, useNotify, useMutation, useRefresh } from 'react-admin';

const ChatBlockSettingsButton = ({record = {}, ...rest }) => {

    const [showModal, setShowModal] = useState(false); 
    const notify = useNotify();
    const refresh = useRefresh();

    const { chat } = rest;  
    const playerId = record._id;
    const playerIsBlocked = chat.blockedUserIds.includes(playerId);
    const updatedBlockedUserIds = playerIsBlocked
        ? chat.blockedUserIds.filter((id) => id !== playerId)
        : [...chat.blockedUserIds, playerId];
    const actionTitle =  playerIsBlocked ? 'Unblock' : 'Block';
    

    const [approve, { loading }] = useMutation(
        {
            type: "update",
            resource: 'Chat',
            payload: { 
                id: chat._id,
                data: { blockedUserIds: updatedBlockedUserIds }
            },
        },
        {
            onSuccess: ({ data }) => {
                // redirect(`/eventlog/${data.id}`);
                setShowModal(false);
                notify(`Player ${record.name} successfully ${actionTitle.toLowerCase()}ed from Chat ${chat._id}`, 'info');
                refresh();
                
            },
            onFailure: (error) => notify(`Report error: ${error.message}`, 'warning'),
        }
    );
    
    return (
        <>
            <AdminButton size="large" label={`${actionTitle} from Chat`} color={playerIsBlocked ? 'primary' : 'default'} onClick={() => setShowModal(true)} disabled={loading} />
                <Dialog
                    open={showModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{actionTitle} Player {record.name} from Chat</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to {actionTitle.toLowerCase()} {record.name}?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => setShowModal(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={approve} color="primary" autoFocus>
                        Confirm
                    </Button>
                    </DialogActions>
                </Dialog>
            </>
    );
};

export default ChatBlockSettingsButton;
