import * as React from "react";
import { 
    SimpleForm,
    TextInput,
    Edit,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    DateInput,
    required
   } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteUserButton from "../DeleteUserButton";
  
export const CouponEdit = (props) => (
  <Edit {...props}>
      <SimpleForm>
          <TextInput disabled label="Id" source="id" />
          <TextInput source="code" validate={required()} />
          <NumberInput source="discount" validate={required()} />
          <DateInput source="expiresAt"/>
          <NumberInput source="maxRedeems" />
          <NumberInput source="maxRedeemsPerUser" />
          <ReferenceInput label="User" source="userId" reference="User">
            <AutocompleteInput optionText="name" />
          </ReferenceInput>                    
      </SimpleForm>
  </Edit>
);  

export default CouponEdit;
  