import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import {
  useMutation,
  useNotify,
  useRedirect,
  useRefresh,
  Button as AdminButton,
} from "react-admin";
import {
  TextField,
  MenuItem,
  Select,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  InputLabel
} from "@material-ui/core";
import { gameStatus } from "../constants";
import { getMatchWinnerId } from "../libs/getMatchWinner";

const useStyles = makeStyles((theme) => ({
  container: {
    bottom: 0,
    // position: "fixed" // remove this so we can apply flex design
  },
  lost: {
    color: "#aaa",
  },
  tb: {
    margin: "0 0 0 5px",
  },
}));

const GameScoreEdit = ({ source, record = {}, ...rest }) => {
  // const GrantWalkoverButton = ({ record }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(record?.status ?? gameStatus.UNFINISHED);
  const [system, setSystem] = useState(
    (record.score && record.score.system) || "free"
  );
  const notify = useNotify();
  const classes = useStyles();
  const [inputs, setInputs] = useState(null);
  const score = record.score;
  const { sets = [], tbs = [] } = score;
  const users = record.users.filter((u) => u.plays === true);
  const { comments = [] } = inputs || {};
  const handleCommentInput = (value, userIndex) => {    
    const _inputs = { ...inputs };
    const newComment = userIndex === 0 ? [value, comments[1]] : [comments[0], value];
    setInputs({
      ..._inputs,
      comments: newComment
    })
  }

  const handleInput = (value, setIndex, userIndex, tiebreak) => {
    const _inputs = { ...inputs };

    let newValue = value ? Number(value) : null;
    if (!tiebreak) {
      let newSets = _inputs.sets.map((set, i) => {
        // do nothing if its not the set user is editing
        if (i !== setIndex) return set;
        // return set;
        const newSet =
          userIndex === 0 ? [newValue, set[1]] : [set[0], newValue];

        return newSet;
      });
      setInputs({
        ..._inputs,
        sets: newSets,
      });
      return;
    }

    let newTbs = _inputs.tbs.map((tb, i) => {
      // do nothing if its the wrong set
      if (i !== setIndex) return tb;

      const newTb = userIndex === 0 ? [newValue, tb[1]] : [tb[0], newValue];

      return newTb;
    });
    setInputs({
      ...inputs,
      tbs: newTbs,
    });
  };
  const checkIndexesAndValidate = () => {
    const { score } = record;

    let _inputs = score.sets && { sets: [...score.sets], tbs: [...score.tbs] };
    let _comments = score.comments || [];
    // if nothing was saved in the server/db before, generate empty inputs
    // this is for mapping and rendering the inputs dynamically
    if (!_inputs || !_inputs.sets.length || !_inputs.sets[0].length) {
      _inputs = {
        sets: [
          [null, null],
          [null, null],
          [null, null],
        ],
        tbs: [
          [null, null],
          [null, null],
          [null, null],
        ],
      };
    }
    _inputs.comments = _comments;
    setInputs(_inputs);
  };
  useEffect(() => {
    checkIndexesAndValidate();
  }, []);
  // const refresh = useRefresh();
  const winnerId = getMatchWinnerId(record);
  const [winnerPlayer, setWinnerPlayer] = useState(
    winnerId ?
      winnerId :
      (record?.score?.winnerId ?
        record?.score?.winnerId :
        null)
    );

  const data = {}
  
  const [approve, { loading }] = useMutation(
    // just updates score without validation, need to find winnerId too and handle reverse maybe
    {
      type: "update",
      resource: "Game",
      payload: {
        id: record.id,
        data: {
          //   status: "Retirement",
          //   unfinished: false,
          score: {
            system,
            ...((winnerPlayer || selectedStatus === gameStatus.UNFINISHED) && {winnerId: winnerPlayer}),
            ...inputs,
          },
          ...((winnerPlayer || selectedStatus === gameStatus.UNFINISHED) && {winnerId: winnerPlayer}),
          ...(selectedStatus && {status: selectedStatus}),
          ...(selectedStatus === gameStatus.UNFINISHED && {booked: true, unfinished: true})
        },
      },
    },
    {
      onSuccess: ({ data }) => {
        setShowModal(false);
        notify(`Score for ${record._id} updated ===`);
        // refresh();
        window.location.reload();
      },
      onFailure: (error) => notify(`Report error: ${error.message}`, "warning"),
    }
  );
  return (
    <>
      <AdminButton
        size="large"
        label="Edit Score"
        onClick={() => setShowModal(true)}
        disabled={false}
      />
      <Dialog
        open={showModal}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Score</DialogTitle>
        <DialogContent>
          {/* <ScoreEdit source={source} record={record} {...rest}/> */}
          <Select
            value={system}
            onChange={(s) => {
              setSystem(s.target.value);
            }}
          >
            <MenuItem value="oneSet">OneSet</MenuItem>
            <MenuItem value="practice">Practice</MenuItem>
            <MenuItem value="standard">Standard</MenuItem>
            <MenuItem value="standardTB">StandardTB</MenuItem>
            <MenuItem value="free">Free</MenuItem>
          </Select>
          {inputs && (
            <Grid container xs={12} spacing={3}>
              <Grid container item xs={12} spacing={3}>
                {score.system === "practice" && (
                  <Grid container item>
                    <span>Practice:</span>
                  </Grid>
                )}
              </Grid>
              <Grid container xs={12} item>
                <Grid className="testing" container item xs={6} spacing={1}>
                  {users.map((user, i) => (
                    <Grid
                      container
                      item
                      alignItems="center"
                      spacing={2}
                      key={i}
                    >
                      <Grid item>
                        <Avatar src={user.data.avatar} />
                      </Grid>

                      <Grid item>
                        <span
                          className={
                            score.winnerId && score.winnerId !== user.data._id
                              ? classes.lost
                              : null
                          }
                          size="16px"
                        >
                          {user.data.name}
                        </span>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                <Grid container item xs>
                  {inputs.sets.map((set, i) => {
                    // if(set.length === 0 || !set[0] && !set[1]) return null
                    // <span>Set {i + 1}</span>

                    return (
                      <Grid
                        key={i}
                        container
                        item
                        alignItems="center"
                        justify="flex-start"
                        xs
                      >
                        {/* {i !== 2 ? 
                  <span>Set {i + 1}</span>
                 : 
                  <span>TB</span>} */}
                        <span>Set {i + 1}</span>
                        {set.map((val, i2) => (
                          <Grid
                            container
                            item
                            // spacing={3}
                            key={i2}
                          >
                            <span
                              size="22px"
                              className={
                                (set[0] < set[1] && i2 === 0) ||
                                (set[0] > set[1] && i2 === 1)
                                  ? classes.lost
                                  : null
                              }
                            >
                              {/* {val} */}
                              <TextField
                                value={
                                  typeof val === "number" ? val.toString() : ""
                                }
                                onChange={(ev) =>
                                  handleInput(ev.target.value, i, i2)
                                }
                                variant="outlined"
                                hasBorder
                                type="number"
                                noClear
                                // disabled={i === 2} // TODO: understand case for disabling
                                // editable={i !== 2 || (i === 2)}
                                set
                              />
                            </span>

                            {set.includes(7) && set[0] + set[1] === 13 && (
                              <TextField
                                // marg="0 -1px"
                                variant="filled"
                                label="TB"
                                placeholder="7"
                                type="number"
                                value={
                                  typeof inputs.tbs[i][i2] === "number"
                                    ? inputs.tbs[i][i2].toString()
                                    : ""
                                }
                                noClear
                                onChange={(ev) =>
                                  handleInput(ev.target.value, i, i2, true)
                                }
                                hasBorder
                                tb
                              />
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

                {comments.length > 0 &&
                  comments.map((c, i) => {
                    if (!users[i]?.data) return null;

                    return (
                      <Grid
                        container
                        item
                        fullWidth
                        alignItems="flex-start"
                        key={i}
                        spacing={1}
                      >
                        <Grid
                          container
                          item
                          alignItems="center"
                          spacing={2}
                          xs={6}
                        >
                          <Grid item>
                            <Avatar src={users[i]?.data?.avatar} />
                          </Grid>

                          <Grid item>
                            <span size="16px">{users[i]?.data?.name}</span>
                          </Grid>
                        </Grid>
                        <Grid xs={6} item>
                          <TextField
                            value={c || ""}
                            onChange={(ev) =>
                              handleCommentInput(ev.target.value, i)
                            }
                            variant="outlined"
                            hasBorder
                            fullWidth
                            noClear
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
          )}
          <Grid container xs={12} spacing={3} style={{marginTop: '20px'}} >
            <Grid>
              <FormControl>
                <FormLabel id="game-status">Game Status</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="game-status"
                  value={selectedStatus}
                  name="radio-buttons-group"
                  onChange={event => {
                    const newStatus = event.target.value;
                    setSelectedStatus(newStatus);
                    if (newStatus === gameStatus.UNFINISHED) {
                      setWinnerPlayer(null);
                    }
                  }}
                >
                  <FormControlLabel
                    value={gameStatus.UNFINISHED}
                    control={<Radio />}
                    label={gameStatus.UNFINISHED}
                  />
                  <FormControlLabel
                    value={gameStatus.PLAYED}
                    control={<Radio />}
                    label={gameStatus.PLAYED}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            { selectedStatus === gameStatus.PLAYED &&
              <Grid xs={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="winnerl">Winner</InputLabel>
                  <Select
                    labelId="winner"
                    id="winner"
                    value={winnerPlayer}
                    label="Winner"
                    onChange={event => {
                      const newWinnerPlayer = event.target.value;
                      setWinnerPlayer(newWinnerPlayer);
                    }}
                  >
                    {
                      users.map(user => <MenuItem key={user.data._id} value={user.data._id}>{user.data.name}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
          </Grid>
          <DialogContentText id="alert-dialog-description">
            {/* Flag {record.data.name} as retired and give win to {otherPlayer?.data?.name}? */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={approve} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GameScoreEdit;
