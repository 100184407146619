import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { useMutation, useNotify, useRefresh, Button as AdminButton } from 'react-admin';
import { gameStatus } from "./constants";

const GameStatusChanger = ({ record, game, newStatus }) => {
    const [showModal, setShowModal] = useState(false); 
    const notify = useNotify();
    const refresh = useRefresh();
    if (!newStatus ||
        (
            newStatus !== gameStatus.CANCELLED &&
            newStatus !== gameStatus.UNFINISHED &&
            newStatus !== gameStatus.DIDNT_PLAY
        ))
    {
        newStatus = gameStatus.CANCELLED;
    }

    const messages = {
        [gameStatus.CANCELLED] : {
            action: 'Cancel Game',
            successNotification: (recordId) => `${recordId} cancelled`,
            confirmationQuestion: (seasonId) => `Are you sure you want to Cancel the ${seasonId ? 'league ':''}game?`
        },
        [gameStatus.DIDNT_PLAY] : {
            action: "Mark Game as Didn't Play",
            successNotification: (recordId) => `${recordId} marked as didn't play`,
            confirmationQuestion: (seasonId) => `Are you sure you want to Mark the ${seasonId ? 'league ':''}game as Didn't Play?`
        },
    }

    const selectedMessages = messages[newStatus];

    const computeCancelGameData = () => {
        const _game = {}
        
        _game.score = {
            comments: ["", ""],
            winnerId: null,
            sets: [],
            tbs: [],
            lastUpdatedBy: null,
            status: null,
        };
        _game.booked = false;
        _game.unfinished = false;

      if (game.seasonId) {
        _game.status = gameStatus.NEW;
        _game.endTime = null;
        _game.court = null;
        _game.courtNo = "";
        _game.location = null;
        _game.startTime = null;
      } else {
        _game.status = newStatus;
      }
      return _game;
    };
    const [update, { loading }] = useMutation(
        {
            type: "update",
            resource: 'Game',
            payload: { 
                id: game.id,
                data: { 
                    ...computeCancelGameData(),
                },
            },
        },
        {
            onSuccess: ({ data }) => {
                // redirect(`/eventlog/${data.id}`);
                setShowModal(false);
                notify(selectedMessages.successNotification(record._id));
                refresh();
            },
            onFailure: (error) => notify(`Report error: ${error.message}`, 'warning'),
        }
    );
    return (
        <>
            <AdminButton size="large" label={selectedMessages.action} onClick={() => setShowModal(true)} disabled={loading} />
            <Dialog
                open={showModal}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{selectedMessages.action}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {selectedMessages.confirmationQuestion(game.seasonId)}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setShowModal(false)} color="primary">
                    No
                </Button>
                <Button onClick={update} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GameStatusChanger;