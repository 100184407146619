const DEFAULT_CURRENCY = "gbp";

const CURRENCY_SYMBOLS = {
    'usd': '$', // US Dollar
    'eur': '€', // Euro
    'gbp': '£', // British Pound Sterling
};

export const convertCurrencyToSymbol = (currency) => {
    if (!currency) {
        return CURRENCY_SYMBOLS[DEFAULT_CURRENCY];
    }
    const lower_case_currency = currency.toLowerCase();
    return CURRENCY_SYMBOLS[lower_case_currency] ? CURRENCY_SYMBOLS[lower_case_currency] : DEFAULT_CURRENCY;
}