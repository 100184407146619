import * as React from "react";
import {
  BooleanField,
  ReferenceField,
  FunctionField,
  ArrayField,
  DateField,
  SingleFieldList,
  TextField,
  ChipField,
  Filter,
  Datagrid,
  List,
  SelectInput,
  NullableBooleanInput,
  TextInput
} from 'react-admin';
import QuickFilter from "../components/QuickFilter";
import SpinPagination from "../components/SpinPagination";
import { timeOptions } from "../constants";
import CustomDataGridBody from "../components/CustomDataGridBody";
import { formatScore } from "../utils";
import PlayerFilter from "../components/filters/PlayerFilter";
import CourtFilter from "../components/filters/CourtFilter";
import CitySelect from "../components/filters/CitySelect";
import PlaceSelect from "../components/filters/PlaceSelect";
import LeagueSelect from "../components/filters/LeagueSelect";
import SeasonSelect from "../components/filters/SeasonSelect";
import PlayersRelationsFilter from "../components/filters/PlayersRelationsFilter";

const GameFilter = props => {
  const hidePlayerFilters = props.hidePlayerFilters ? props.hidePlayerFilters : false;
  return (
    <Filter {...props}>
      {/* <QuickFilter source="owner" label="League Games" defaultValue={"null"} /> */}
      <QuickFilter source="hosted" label="Friendly Games" defaultValue={true} />
      <QuickFilter source="full" label="Joined Games" defaultValue={true} />
      <QuickFilter source="booked" label="Court Booked" defaultValue={true} />
      {/* <QuickFilter source="full" label="Open Games" defaultValue={false} /> */}
      {/* <TextInput label="Status" source="status" defaultValue={null} /> */}
      <SelectInput
        choices={[
          { id: 'New', name: 'New' },
          { id: 'Played', name: 'Played' },
          { id: 'Cancelled', name: 'Cancelled' },
          { id: 'Expired', name: 'Expired' },
          { id: 'Unfinished', name: 'Unfinished' },
          { id: "Didn't Play", name: "Didn't Play" },
          { id: 'Add Score', name: 'Add Score' },
          { id: 'Walkover', name: 'Walkover' },
          { id: 'Retirement', name: 'Retirement' },
        ]}
        source="status"
      />
      <CitySelect
        label="City"
        source="location.cityId"
      />
      <PlaceSelect
        source="location.placeId"
        label="Place"
      />
      <LeagueSelect
        source="leagueId"
        label="League"
      />
      <SeasonSelect
        source="seasonId"
        label="Season"
      />
      <CourtFilter
        label="Court"
        source="court"
      />
      {/*<TextInput label="Player" source="player" defaultValue={null} />*/}
      {!hidePlayerFilters &&
        <PlayerFilter
          source="player"
        />
      }
      <PlayersRelationsFilter
        label="Opponents"
        source="opponents"
      />
      <NullableBooleanInput source="hidden" />
      <SelectInput
        source="gender"
        choices={[
          { id: "all", name: "All" },
          { id: "male", name: "Male" },
          { id: "female", name: "Female" }
        ]}
      />
      {/*<TextInput label="Season" source="seasonId" defaultValue={null} />*/}
    </Filter>
  )
};

export const GameList = props => {
  const resource = {
    ...(props.source && { resource: props.source })
  }

  return (
    <List {...props} {...resource} perPage={50} filters={<GameFilter hidePlayerFilters={props.hidePlayerFilters} />} pagination={<SpinPagination />} sort={{ field: 'createdAt', order: 'DESC' }} exporter={false}>
      <Datagrid rowClick="show" body={<CustomDataGridBody resourceType="game" />}>
        <ReferenceField link="show" label="Owner" source="owner" reference="User">
          <TextField source="name" />
        </ReferenceField>
        {/* <ReferenceField link="show" label="Season" source="seasonId" reference="Season">
            <FunctionField
              label="Season"
              render={record => `${record.league.name} - ${record.name}`}
            />
          </ReferenceField>               */}
        <ArrayField label="Players" source="users">
          <SingleFieldList>
            <ChipField source="data.name" />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Score"
          render={record => formatScore(record, true)}
        />
        {/* <BooleanField source="hosted" />       */}
        <TextField label="Location" source="location.text" />
        {/* <ReferenceArrayField label="Players" reference="User" source="users.data">
            <SingleFieldList linkType="show">
                <ChipField source="name"/>
            </SingleFieldList>
          </ReferenceArrayField> */}
        {/* <ArrayField label="Players" source="players">
            <Datagrid>
              <ReferenceField link="show" label="Player" source="data" reference="users">
                <TextField source="name" />
              </ReferenceField>                           
              <BooleanField source="plays" />
              <BooleanField source="invited" />
            </Datagrid>
          </ArrayField>           */}
        <BooleanField source="hidden" />
        <BooleanField source="full" />
        <TextField source="skillLevel" label="Skill" />
        <TextField source="gender" label="Gender" />
        <TextField source="flexibleDates" label="Flexible" />
        <TextField source="source" />
        <DateField label="Created" source="createdAt" options={timeOptions} locales="en-GB" />
        <DateField label="Start" source="startTime" showTime={true} options={timeOptions} locales="en-GB" />
        <TextField source="status" />
        <TextField source="courtNo" label="Court" />
        <BooleanField source="booked" />
      </Datagrid>
    </List>
  )
};


export default GameList;
