import { gql } from '@apollo/client';
const LINK_PAYMENTS_AND_SKILL_FROM_EMAIL = gql`
  mutation linkPaymentsAndSkillFromEmail($userId: MongoID!, $webEmail: String) {
    linkPaymentsAndSkillFromEmail(userId: $userId, webEmail: $webEmail) {
        modifiedPayments
        email
    }
  }
`;

export default LINK_PAYMENTS_AND_SKILL_FROM_EMAIL;