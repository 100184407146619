const authProvider = {
  login: ({ username, password }) =>  {
      const request = new Request(`${process.env.REACT_APP_API_URL}/users/login`, {
          method: 'POST',
          body: JSON.stringify({ email: username, password }),
          headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      return fetch(request)
          .then(response => {
              if (response.status < 200 || response.status >= 300) {
                  throw new Error(response.statusText);
              }
              return response.json();
          })
          .then(({ token, user }) => {
            if (user.admin) {
              localStorage.setItem('token', token);
            } else {
              throw new Error("Access denied");
            }              
          });
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkAuth: () => localStorage.getItem('token')
    ? Promise.resolve()
    : Promise.reject(),
  checkError: error => Promise.resolve(),
  getPermissions: params => Promise.resolve(),  
  // ...
};

export default authProvider;