import React from "react";

export const playerInputText = choice => choice.name;
  
export const playerMatchSuggestion = (filter, choice) => {
  return  choice.name.toLowerCase().includes(filter.toLowerCase());
}

export const nameFilterToQuery = searchText => ({ q: searchText });

const PlayerOption = ({ record }) => {
    return record ?
      (
        <div style={{
          display: "flex",
          alignItems: "center"}}
        >
          {record.avatar && <img
            src={record.avatar}
            style={{
              verticalAlign: "middle",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
            }}
            alt={record.name}
          />}
          <div style={{marginLeft: "10px"}}>
            <div>{record.name}</div>
            <div style={{fontSize: "0.75rem"}}>
              {record._id}
            </div>
          </div>
        </div>
      ) : <></>;
};

export const playerOptionText = (choice) => <PlayerOption record={choice} />;

export default PlayerOption;