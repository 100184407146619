import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { useMutation, useNotify, useRedirect, useRefresh, Button as AdminButton } from 'react-admin';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    }
  });

const DeleteUserButton = ({ record }) => {
// const DeleteUserButton = ({ record }) => {       
    const [showModal, setShowModal] = useState(false); 
    // console.log("Player is ", record)
    // console.log("Game is ", game.id)
    const notify = useNotify();
    const refresh = useRefresh();
    // const redirect = useRedirect();
    const [approve, { loading }] = useMutation(
        {
            type: "update",
            resource: 'User',
            payload: { 
                id: record.id,
                data: { 
                    deleted: true, 
                    hidden: true, 
                    firstName: 'Spin',
                    lastName: 'User',
                    name: 'Spin User',
                    email: `user${record.id}@mailinator.com`,
                    devices: [],
                    notificationSettings: {
                        unsubscribe: true,
                        messages: {
                            email: false,
                            push: false,
                        },
                        nearby: {
                            email: false,
                            push: false,
                        },
                    },
                    mobile: null,
                    avatar: 'https://spin-production.s3.eu-west-2.amazonaws.com/uploads/user-silhouette.png',
                },
            },
        },
        {
            onSuccess: ({ data }) => {
                // redirect(`/eventlog/${data.id}`);
                setShowModal(false);
                notify(`Deleted user ${record._id}`);
                refresh();
                
            },
            onFailure: (error) => notify(`Report error: ${error.message}`, 'warning'),
        }
    );
    return (
        <>
            <AdminButton size="large" label="DELETE" color="secondary" onClick={() => setShowModal(true)} disabled={loading} />
            <Dialog
                open={showModal}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete User (Flag)</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Delete {record.name}?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setShowModal(false)} color="secondary">
                    Cancel
                </Button>
                <Button onClick={approve} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeleteUserButton;