import GeographicAreaEdit from './GeographicAreaEdit';
import GeographicAreaList from './GeographicAreaList';
import GeographicAreaShow from './GeographicAreaShow';
import GeographicAreaCreate from './GeographicAreaCreate';

export default {
    list: GeographicAreaList,
    create: GeographicAreaCreate,
    edit: GeographicAreaEdit,
    show: GeographicAreaShow,
    // icon: PeopleIcon,
};