const PRO_SET_GAMES = 8;
const FREE_SET_GAMES = 6;
const STANDARD_SET_GAMES = 6;
const STANDARD_TB = 10;

const isSetWinner = (game, playerId, set, setIndex) => {
  // check if player won set and ensure set is complete
  console.log('yooyoyoy', game.users[0].data._id);
  
  const isFirstUser = game.users[0].data._id === playerId;
  //console.log(`comparing score ${set}`)
  let currentPlayerSetScore = isFirstUser ? set[0] : set[1];
  let otherPlayerSetScore = isFirstUser ? set[1] : set[0];
  //console.log(`is First user ${true}`)

  const wonSet = (
    currentPlayerSetScore,
    otherPlayerSetScore,
    minGames,
    hasTB,
  ) => {
    return (
      (currentPlayerSetScore - otherPlayerSetScore >= 2 &&
        currentPlayerSetScore >= minGames) ||
      (hasTB && currentPlayerSetScore == minGames + 1)
    );
  };
  let isWinner = false;
  if (currentPlayerSetScore > otherPlayerSetScore) {
    switch (game.score.system) {
      // case 'oneSet':
      //   isWinner = wonSet(currentPlayerSetScore, otherPlayerSetScore, FREE_SET_GAMES, false);
      //   break;
      case 'standard':
      case 'oneSet':
        isWinner = wonSet(
          currentPlayerSetScore,
          otherPlayerSetScore,
          STANDARD_SET_GAMES,
          true,
        );
        break;
      case 'standardTB':
        isWinner =
          (setIndex < 2 &&
            wonSet(
              currentPlayerSetScore,
              otherPlayerSetScore,
              STANDARD_SET_GAMES,
              true,
            )) ||
          (setIndex == 2 &&
            wonSet(
              currentPlayerSetScore,
              otherPlayerSetScore,
              STANDARD_TB,
              false,
            ));
        // (currentPlayerSetScore - otherPlayerSetScore >= 2 &&
        //   currentPlayerSetScore >= 10));
        break;
      default:
        break;
    }
    return isWinner;
  }
  return isWinner;
};

export default isSetWinner;
