export const timeOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: "Europe/London" };
export const courtChoices = [{id: "Park", name: "Park"},{id: "Club", name: "Club"},{id: "School", name: "School"},{id: "Hotel", name: "Hotel"}, {id: "Private", name: "Private"},{id: "Public", name: "Public"}];
export const accessChoices = [{id: "Free to Play", name: "Free to Play"},{id: "Pay & Play", name: "Pay & Play"},{id: "Membership required", name: "Membership required"},{id: "Private", name: "Private"}];
export const bookingChoices = [{id: "Book Online", name: "Book Online"},{id: "Telephone", name: "Telephone"},{id: "Turn up & play", name: "Turn up & play"}];
export const gameStatus = Object.freeze({
  NEW: 'New',
  PLAYED: 'Played',
  UNFINISHED: 'Unfinished',
  DIDNT_PLAY: "Didn't Play",
  ADD_SCORE: 'Add Score',
  CANCELLED: 'Cancelled',
  WALKOVER: 'Walkover',
  EXPIRED: 'Expired',
  INJURY: 'Injury',
  RETIREMENT: 'Retirement',
});

export const sysMessageTypes = Object.freeze({
  ADD_RESULT: "add_result",
  RESULT_CONFIRMED: "result_confirmed",
  DIDNT_PLAY: "didnt_play",
  GAME_JOINED: "game_joined",
  RESULT_ADDED: "result_added",
  RESULT_UPDATED: "result_updated",
  GAME_CANCELLED: "game_cancelled",
  LEAGUE_GAME_CANCELLED: "league_game_cancelled",
  GAME_DATE_CHANGED: "game_date_changed",
  GAME_LOCATION_CHANGED: "game_location_changed",
  INVITATION_DECLINED: "invitation_declined",
  GAME_PLAYER_REMOVED: "game_player_removed",
  PLAYER_LEFT_GAME: "left_game",
  GAME_CREATED: 'game_created',
  UPCOMING_GAME: 'upcoming_game'
});

export const walkoverStatuses = {
  CANCELLED_NO_NOTICE: 'Cancellation less than 24 hrs',
  NO_SHOW: "No show",
  LATE_TO_GAME: "Over 30 mins late to the game",
  NOT_RESPONSIVE: "Not responsive after 3 attempts to contact",
  UNABLE_TO_ARRANGE:"Unable to arrange a game after 3 attempts",
  WITHDRAWAL: "Opponent has withdrawn from the league",
};