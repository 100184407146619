import React from "react";
import { useFormState } from 'react-final-form';
import { SelectInput, useQuery } from "react-admin";

const LeagueSelect = props => {
    const { data: leagueData, loading: leagueFilterLoading, error: leagueFilterError } = useQuery({ 
        type: 'getList',
        resource: 'League',
        payload: { pagination: { page: 1, perPage: 50 } }
    });

    return (
        <SelectInput
            choices={leagueData}
            isLoading={leagueFilterLoading}
            {...props}
            optionText="name"
            optionValue="_id"
        />        
    )
  }

  export default LeagueSelect;