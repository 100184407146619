import * as React from "react";
import {
    Button,
    Labeled,
    TextField,
    useNotify
} from 'react-admin';

import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';


const CopyFieldWrapper = ({ source, label = '', record, ReactAdminComponent = TextField }) => {
    const notify = useNotify();

    if (!record[source]) return null;

    const handleCopy = async (event) => {
        event.preventDefault();
        try {
            await navigator.clipboard.writeText(record[source]);
            notify(`"${source}" copied to clipboard`)
        } catch (error) {
            notify(`Error copying "${source}" to clipboard: `, error);
        }
    };

    const CopyButton = () => (
        <Button
            onClick={handleCopy}>
            <FileCopyOutlined />
        </Button>
    );


    return (
        <Labeled label={
            label ?
                <span>{label}</span> :
                <></>
        }>
            <div style={{ display: 'flex' }}>
                <ReactAdminComponent record={record} source={source} />
                <CopyButton />
            </div>
        </Labeled>
    );
}

export default CopyFieldWrapper;