import * as React from "react";
import {
    List,
    Datagrid,
    SingleFieldList,
    ChipField,
    TextField,
    DateField,
    ReferenceArrayField,
    ArrayField,
    Filter,
    TextInput,
    linkToRecord
} from 'react-admin';
import PlayersRelationsFilter from "../components/filters/PlayersRelationsFilter";
import SpinPagination from "../components/SpinPagination";
import { timeOptions } from "../constants";
import { handleRowClick } from "../libs/handleRowClick";

const ChatFilter = props => {
    const hidePlayerFilters = props.hidePlayerFilters ? props.hidePlayerFilters : false;
    return (
        <Filter {...props}>
            {/*<TextInput label="Player" source="player" defaultValue={null} />*/}
            <TextInput label="Id" source="_id" />
            <TextInput label="Name" source="q" defaultValue="" />
            <TextInput label="Topic" source="topicq" defaultValue="" />
            {/*<TextInput label="Chat Messages" source="chatMessages" defaultValue={null} />*/}
            {!hidePlayerFilters &&
                <PlayersRelationsFilter
                    label="Participants"
                    source="participants"
                />
            }
        </Filter>
    )
};

const ChatList = props => {
    const resource = {
        ...(props.source && { resource: props.source })
    }

    return (
        <List {...props} {...resource} filters={<ChatFilter hidePlayerFilters={props.hidePlayerFilters} />} pagination={<SpinPagination />} perPage={50} sort={{ field: 'createdAt', order: 'DESC' }} exporter={false}>
            <Datagrid rowClick={(id, resource, record) => handleRowClick(id, 'Chat')}>
                <TextField source="id" />
                <ReferenceArrayField label="Players" reference="User" source="playerIds">
                    <SingleFieldList linkType="show">
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ArrayField label="Seasons" source="seasonsPlayed">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                {/* <ReferenceArrayField source="seasonIds" reference="seasons"><TextField source="id" /></ReferenceArrayField> */}
                {/* <BooleanField source="club" /> */}
                {/* <ReferenceField source="_id" reference="s"><TextField source="id" /></ReferenceField>
            <ReferenceField source="oldId" reference="olds"><TextField source="id" /></ReferenceField>
            <ReferenceField source="oldCourtId" reference="oldCourts"><TextField source="id" /></ReferenceField> */}
                {/* <TextField source="name" /> */}
                <TextField label="Last Msg" source="lastMessage.body" />
                <TextField source="name" />
                <TextField source="topic" />
                <DateField source="createdAt" showTime={true} options={timeOptions} locales="en-GB" />
                <DateField source="updatedAt" showTime={true} options={timeOptions} locales="en-GB" />
                {/* <ImageField source="logo" /> */}
                {/* <AvatarField source="logo" />         */}
                {/* <NumberField source="__v" /> */}
            </Datagrid>
        </List>
    )
};

export default ChatList;