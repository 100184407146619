import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { useMutation, useNotify, useRedirect, useRefresh, Button as AdminButton } from 'react-admin';
import { gql, useMutation as useApolloMutation } from '@apollo/client';
import RECALCULATE_STANDINGS from "./graphql/mutations/recalculateStandingsMutation";
import client from "./graphql/client";

const MarkRetirement = ({ record, game }) => {
// const GrantWalkoverButton = ({ record }) => {   
    const [showModal, setShowModal] = useState(false); 
    const notify = useNotify();
    const refresh = useRefresh();
    const otherPlayer =  game.users.find(u => u.data._id !== record.data._id);
     const [recalculateStandings, { loading: reacalculateLoading }] = useApolloMutation(RECALCULATE_STANDINGS, {
        onCompleted: (data) => {
            setShowModal(false);
            notify(`Standing recalculated`);
            refresh();
        },
        onError: (error) => notify(`Report error: ${error.message}`, 'warning'),
        client,
        variables: {
            id: game.id,
        }
    });
    // const redirect = useRedirect();
    const [approve, { loading }] = useMutation(
        {
            type: "update",
            resource: 'Game',
            payload: { 
                id: game.id,
                data: { 
                    status: "Retirement",
                    unfinished: false,
                    score: {
                      winnerId: otherPlayer?.data?._id,
                    },
                    winnerId: otherPlayer?.data?._id
                },
            },
        },
        {
            onSuccess: ({ data }) => {
                // redirect(`/eventlog/${data.id}`);
                
                if(game.seasonId) {
                    notify(`${record.data._id} marked as retired. ${game.seasonId ? 'Recalculating standing':''}`)
                    game.seasonId && recalculateStandings();
                } else {
                    setShowModal(false);
                    notify(`${record.data._id} marked as retired`)
                    refresh();
                }
                
            },
            onFailure: (error) => notify(`Report error: ${error.message}`, 'warning'),
        }
    );
    return (
        <>
            <AdminButton size="large" label="Mark as Retired" onClick={() => setShowModal(true)} disabled={loading} />
            <Dialog
                open={showModal}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Mark as Retired</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Flag {record.data.name} as retired and give win to {otherPlayer?.data?.name}?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setShowModal(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={approve} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MarkRetirement;