import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Filter,
    TextInput,
    BooleanField,
    SelectInput,
    NullableBooleanInput
} from 'react-admin';
import SpinPagination from "../components/SpinPagination";
import { timeOptions } from "../constants";

export const CourtList = props => {
    const CourtFilter = props => (
        <Filter {...props}>
            <TextInput label="Name" source="search" defaultValue="" />
            <TextInput label="Id" source="_id" defaultValue={null} />
            <SelectInput
                label="Type"
                source="courtType"
                choices={[
                    {id: "Club", name: "Club"},
                    {id: "Park", name: "Park"},
                    {id: "School", name: "School"}
                ]}
            />
            <NullableBooleanInput label="Private Court" source="privateCourt" />
        </Filter>
    );
    // return null;
    return (<List {...props} filters={<CourtFilter />} pagination={<SpinPagination />} perPage={50} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="phone" />
            <TextField source="courtType" label="Type" />
            <TextField source="access" />
            <TextField source="bookingType" label="Booking" />
            <BooleanField source="privateCourt" label="Private" />
            <BooleanField source="lights" />
            {/* <ReferenceArrayField label="Players" reference="User" source="playerIds">
            <SingleFieldList linkType="show">
                <ChipField source="name"/>
            </SingleFieldList>
            </ReferenceArrayField>*/}
            <DateField source="createdAt" showTime={true} options={timeOptions} locales="en-GB" />
            {/* <DateField source="updatedAt" /> */}
        </Datagrid>
    </List>);
};

export default CourtList;
