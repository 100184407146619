import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Button, Select, MenuItem, InputLabel, Divider } from '@material-ui/core';
import { useMutation, useNotify, useRedirect, useRefresh, Button as AdminButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useMutation as useApolloMutation } from '@apollo/client';
import RECALCULATE_STANDINGS from "./graphql/mutations/recalculateStandingsMutation";
import client from "./graphql/client";
import { walkoverStatuses } from "./constants";
import { humanize } from 'lodash';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    width: 300,
    // minWidth: 120,
  },
}));

const GrantWalkoverButton = ({ record, game }) => {
// const GrantWalkoverButton = ({ record }) => {   
    const [showModal, setShowModal] = useState(false); 
    // console.log("Player is ", record)
    // console.log("Game is ", game.id)
    const notify = useNotify();
    const refresh = useRefresh();
     const classes = useStyles();
    const [walkoverStatus, setWalkoverStatus] = useState(null);
    const [submitClicked, setSubmitClicked] = useState(false);
    // const redirect = useRedirect();
    const [recalculateStandings, { loading: reacalculateLoading }] = useApolloMutation(RECALCULATE_STANDINGS, {
        onCompleted: (data) => {
            setShowModal(false);
            // notify(`Walkover granted to ${record.data._id}`);
            notify(`Standing recalculated`);
            refresh();
        },
        onError: (error) => notify(`Report error: ${error.message}`, 'warning'),
        client,
        variables: {
            id: game.id,
        }
    });
    const [approve, { loading }] = useMutation(
        {
            type: "update",
            resource: 'Game',
            payload: { 
                id: game.id,
                data: { 
                    status: "Walkover",
                    unfinished: false,
                    score: {
                        tbs: [
                        [null, null],
                        [null, null],
                        [null, null],
                        ],
                        sets: [
                        [null, null],
                        [null, null],
                        [null, null],
                        ],
                      comments: [ "", "" ],
                      winnerId: record.data._id,
                      walkoverStatus,
                    },
                    winnerId: record.data._id
                },
            },
        },
        {
            onSuccess: ({ data }) => {
                if(game.seasonId) {
                    notify(`Walkover granted to ${record.data._id}. ${game.seasonId ? 'Recalculating standing':''}`);
                    game.seasonId && recalculateStandings();
                } else {
                    setShowModal(false);
                    notify(`Walkover granted to ${record.data._id}.`)
                    refresh();
                }
                
            },
            onFailure: (error) => notify(`Report error: ${error.message}`, 'warning'),
        }
    );
    return (
        <>
            <AdminButton size="large" label="Grant Walkover" onClick={() => {
                setShowModal(true);
                setWalkoverStatus(false);
                setSubmitClicked(false);
            }} disabled={loading} />
            <Dialog
                open={showModal}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Set as Walkover</DialogTitle>
                <DialogContent>
                    <FormControl
                        className={classes.formControl}
                        error={submitClicked && !walkoverStatus ? "Required" : null}
                        required
                        >
                        <InputLabel>Walkover Status</InputLabel>
                        <Select
                            defaultValue={game.score && game.score.walkoverStatus}
                            style={{ width: "100%" }}
                            onChange={(s) => {
                            setWalkoverStatus(s.target.value);
                            }}
                        >
                            {Object.values(walkoverStatuses).map((s) => (
                            <MenuItem value={s}>{s}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                <DialogContentText id="alert-dialog-description">
                    Grant {record.data.name} a Walkover?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setShowModal(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {
                    setSubmitClicked(true);
                    walkoverStatus && approve();
                }} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GrantWalkoverButton;