import * as React from "react";
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { linkToRecord, useRecordContext, DateField } from 'react-admin';
import { Link } from 'react-router-dom';
import AvatarField from "./AvatarField";

const useStyles = makeStyles(theme => ({
  container: {
    bottom: 0
    // position: "fixed" // remove this so we can apply flex design
  },
  bubbleContainer: {
    width: "100%",
    display: "flex" //new added flex so we can put div at left and right side
    //check style.css for left and right classnaeme based on your data
  },
  bubble: {
    //border: "0.5px solid black",
    borderRadius: "10px",
    backgroundColor: "#F1F6F9",
    color: theme.palette.primary.contrastText,
    margin: "5px 0",
    padding: "10px",
    display: "inline-block"
  },
  bubbleSecondary: {
    //border: "0.5px solid black",
    borderRadius: "10px",
    backgroundColor: "#EBFFF7",
    color: theme.palette.primary.contrastText,
    margin: "5px 0",
    padding: "10px",
    display: "inline-block"
  },
  right: {
    justifyContent: "flex-end !important",
  },
  left: {
    justifyContent: "flex-start !important",
  }
}));

const ChatMessage = ({ source, record = {}, ...rest }) => {
  ///console.log(rest);
  const timeOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: "Europe/London" };

  const { chat } = rest;
  const classes = useStyles();

  const isGroupChat = chat.playerIds.length > 2;

  const isFirstUser = chat.playerIds[0] === record.sender;
  const player = record.from;
  const createdAt = new Date(record.createdAt);
  if (record.sysMessage) {
    const linkToGame = linkToRecord('/game', record.gameId, 'show');
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        key={record._id}
        id={record._id}
      >
        <span>{createdAt.toLocaleDateString("en-GB", timeOptions)}</span>
        <DateField
          label="Created"
          source="record.createdAt"
          options={timeOptions}
          locales="en-GB"
        />
        <span>
          {player ? player.name : ""} {record.body}{" "}
          <Link to={linkToGame}>View game</Link>
        </span>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems={isGroupChat || isFirstUser ? 'flex-start' : 'flex-end'}
        key={record._id}
        id={record._id}>
        <Grid
          container
          direction={isGroupChat || isFirstUser ? "row" : "row-reverse"}
          alignItems="center"
          justify='flex-start'
        >
          <img
            src={player.avatar}
            style={
              {
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                ...((isGroupChat || isFirstUser) && {marginRight: '10px'}),
                ...((!isGroupChat && !isFirstUser) && {marginLeft: '10px'})
              }
            }
          />
          <Grid
            container
            direction="column"
            justify="center"
            alignItems={isGroupChat || isFirstUser ? 'flex-start' : 'flex-end'}
            xs={4}
          >
            <span>{player.name}</span>
            <span>{createdAt.toLocaleDateString('en-GB', timeOptions)}</span>
          </Grid>
        </Grid>
        {record.readBy.length > 0 && <span>✓ Seen</span>}
        <div
          className={`${classes.bubbleContainer} ${isGroupChat || isFirstUser ? classes.left : classes.right}`}>
          <div className={isGroupChat || isFirstUser ? classes.bubble : classes.bubbleSecondary}>
            <div className={classes.button}>{record.body}</div>
          </div>
        </div>
      </Grid>
    )
  }
}

ChatMessage.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ChatMessage;