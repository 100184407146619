// import PeopleIcon from '@material-ui/icons/People';
// import SeasonCreate from './UserCreate';
import SeasonEdit from './SeasonEdit';
// import UserList from './UserList';
// import SeasonShow from './UserShow';

export default {
    // list: UserList,
    // create: UserCreate,
    edit: SeasonEdit,
    // show: UserShow,
    // icon: PeopleIcon,
};