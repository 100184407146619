import React, { useState, useEffect } from "react";

const GoogleScriptLoaded = (props) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  useEffect(() => {
    const interval = setInterval(function () {
      if (window.google) {
        setScriptLoaded(true);
        clearInterval(interval);
      }
    }, 200);
    return () => clearInterval(interval);
  }, []);
  if (!scriptLoaded) {
    return null;
  }
  return props.children instanceof Function ? props.children() : props.children;
};

export default GoogleScriptLoaded;
