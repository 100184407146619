import * as React from "react";
import { 
  Admin, 
  required,
  Resource, 
  List, 
  Datagrid, 
  SingleFieldList,
  ChipField,
  NumberField, 
  TextField, 
  DateField, 
  BooleanField, 
  SelectInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  ReferenceArrayInput,
  ReferenceArrayField,
  Filter,
  TextInput,
  DateInput,
  BooleanInput,
  Edit,
  Create,
  SimpleForm,
  Pagination,
  UrlField
 } from 'react-admin';
import spinAuthProvider from './authProvider'
import { buildQuery } from './graphql/buildQuery'
import theme from './theme'
import AvatarField from "./AvatarField";
// import JsonField from "./JsonField";
import { useEffect } from "react";
import buildGraphQLProvider from 'ra-data-graphql';
import { useState } from "react";
import { introspection, introspectionOperationNames } from './graphql/introspection';
import seasons from "./seasons";
import users from "./users";
import geographicAreas from "./geographicAreas";
import courts from "./courts";
import games from "./games";
import coupons from "./coupons";
import skillAssesments from "./skillAssesments";
import chats from "./chats";
import payments from "./payments";
import chatMessages from "./chatMessages";
import client from "./graphql/client";
import playerProgressLogs from "./playerProgressLogs";
import PlayerFilter from "./components/filters/PlayerFilter";
import { timeOptions } from "./constants";

const SpinPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

// export const GameList = props => (
//   <List {...props} filters={<GameFilter/>}>
//       <Datagrid rowClick="show">          
//           <ReferenceField link="show" label="Owner" source="owner" reference="users">
//             <TextField source="name" />
//           </ReferenceField>
//           <ReferenceField link="show" label="Season" source="seasonId" reference="seasons">
//             <TextField source="name" />
//           </ReferenceField>          
//           {/* <BooleanField source="hosted" />       */}
//           <TextField label="Location" source="location.text" />
//           <ReferenceArrayField label="Players" reference="users" source="players">
//             <SingleFieldList linkType="show">
//                 <ChipField source="name"/>
//             </SingleFieldList>
//           </ReferenceArrayField>
//           {/* <ArrayField label="Players" source="users">
//             <Datagrid>
//               <ReferenceField link="show" label="Player" source="data" reference="users">
//                 <TextField source="name" />
//               </ReferenceField>                           
//               <BooleanField source="plays" />
//               <BooleanField source="invited" />
//             </Datagrid>
//           </ArrayField> */}        
//           {/* <TextField source="score.comments" /> */}
//           {/* <TextField source="invited" /> */}
//           <BooleanField source="hidden" />
//           <BooleanField source="full" />
//           <TextField source="skillLevel" label="Skill"/>
//           {/* <ReferenceField source="_id" reference="s"><TextField source="id" /></ReferenceField> */}
//           <DateField label="Created" source="createdAt" options={{ year: 'numeric', month: 'short', day: 'numeric' }} />
//           <DateField label="Start" source="startTime" showTime={true} options={{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
//           {/* <DateField source="endTime" /> */}
//           {/* <ReferenceField source="court._id" reference="court.s"><TextField source="id" /></ReferenceField> */}
//           {/* <ArrayField source="users"><SingleFieldList><ChipField source="notifCount" /></SingleFieldList></ArrayField> */}
//           {/* <TextField source="owner" /> */}
//           <TextField source="status" />
//           {/* <TextField source="cursors" />
//           <TextField source="lastMessage" /> */}
//           <TextField source="courtNo" label="Court" />
//           <BooleanField source="booked" />
//           {/* <TextField source="id" /> */}
//           {/* <ReferenceField source="seasonId" reference="seasons"><TextField source="id" /></ReferenceField> */}
//       </Datagrid>
//   </List>
// );

// const GameShow = props => (
//   <Show {...props}>
//     <SimpleShowLayout>
//       <TextField source="location.text" />
//       <DateField source="startTime" />
//       <DateField source="endTime" />
//       <ReferenceManyField
//         reference="messages"
//         target="id"
//         label="Comments"
//       > 
//         {/* <Datagrid>
//           <Message source="createdAt" {...props}/>
//         </Datagrid> */}
//         <Messages game={props.record}/>
//       </ReferenceManyField>
//     </SimpleShowLayout>
//   </Show>
// );


export const LeagueList = props => (
  <List {...props} pagination={<SpinPagination />} perPage={50} exporter={false}>
      <Datagrid rowClick="show">
          <TextField source="id" />
          {/* <ReferenceArrayField source="seasonIds" reference="seasons"><TextField source="id" /></ReferenceArrayField> */}
          <BooleanField source="club" />
          {/* <ReferenceField source="_id" reference="s"><TextField source="id" /></ReferenceField>
          <ReferenceField source="oldId" reference="olds"><TextField source="id" /></ReferenceField>
          <ReferenceField source="oldCourtId" reference="oldCourts"><TextField source="id" /></ReferenceField> */}
          <TextField source="name" />
          <DateField source="createdAt" showTime={true} options={timeOptions} locales="en-GB" />
          {/* <ImageField source="logo" /> */}
          <AvatarField source="logo" />        
          <NumberField source="__v" />
      </Datagrid>
  </List>
);

export const MessageList = props => (
  <List {...props} exporter={false}>
      <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="body" />
          <DateField source="createdAt" showTime={true} options={timeOptions} locales="en-GB" />
      </Datagrid>
  </List>
);

export const LeagueEdit = (props) => (
  <Edit {...props}>
      <SimpleForm>
          <TextInput disabled label="Id" source="id" />
          <TextInput source="name" validate={required()} />
          <TextInput source="shortName" validate={required()} />
          <TextInput source="logo" validate={required()} />
          <TextInput source="slug" />
          <SelectInput source="currency" choices={[{id: "gbp", name: "GBP"},{id: "usd", name: "USD"}]} />
          <BooleanInput source="hidden" />
          <BooleanInput source="webHidden" />
          {/* <TextInput multiline source="teaser" validate={required()} /> */}
          {/* <RichTextInput source="body" validate={required()} /> */}
          {/* <DateInput label="Publication date" source="published_at" /> */}
          {/* <ReferenceManyField label="Comments" reference="comments" target="post_id">
              <Datagrid>
                  <TextField source="body" />
                  <DateField source="created_at" />
                  <EditButton />
              </Datagrid>
          </ReferenceManyField> */}
      </SimpleForm>
  </Edit>
);

export const LeagueShow = (props) => (
  <Show {...props}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <TextField source="name" />
        <TextField source="shortName" />
        <UrlField source="logo" validate={required()} />
        <TextField source="slug" />
        {/* <TextField source="currency" /> */}
        <BooleanField source="hidden" />
        <BooleanField source="webHidden" />
      </SimpleShowLayout>
    </Show>
);

const SeasonShow = (props) => (
  <Show {...props}>
      <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="price" />
          <TextField source="annualPrice" />
          <TextField source="info" />
          <TextField source="rules" />
      </SimpleShowLayout>
  </Show>
);

// phone: String,
// open: [{
//   label: String,
//   hours: String
// }],
// courtType: String,
// privateCourt: Boolean,
// access: String,
// bookingType: String,
// lights: Boolean,
// indoorCourts: [{
//   label: String,
//   count: Number
// }],
// outdoorCourts: [{
//   label: String,
//   count: Number
// }],
// indoorPrice: String,
// outdoorPrice: String,
// membershipPrice: String,
// info: String,
// internalNotes: String          


// EventLogs

const EventLogFilter = props => (
  <Filter {...props}>
      {/*<TextInput label="Player Id" source="playerId" defaultValue={null} />*/}
      <PlayerFilter label="Player" source="playerId"/>
  </Filter>
);

export const EventLogList = props => (
  <List {...props} filters={<EventLogFilter/>} pagination={<SpinPagination />} perPage={50} exporter={false}>
      <Datagrid rowClick="show">
          {/* <TextField source="id" /> */}
          <ReferenceArrayField label="Players" reference="User" source="playerIds">
            <SingleFieldList linkType="show">
                <ChipField source="name"/>
            </SingleFieldList>
          </ReferenceArrayField>
          <TextField source="type" />
          <TextField source="source" />          
          <BooleanField source="gameId" looseValue={true}/>
          <DateField source="date" options={timeOptions} locales="en-GB" />
          <DateField source="createdAt" showTime={true} options={timeOptions} locales="en-GB" />
          <TextField source="notes" />
      </Datagrid>
  </List>
);

export const EventLogEdit = (props) => (
  <Edit {...props}>
      <SimpleForm>
          <SelectInput source="type" choices={[
            { id: 'game_played', name: 'Game Played' },
          ]} validate={required()} />          
          <TextInput disabled source="source" initialValue="admin"/>
          <DateInput source="date" />
          <ReferenceArrayInput label="Players" source="playerIds" reference="User">
            <AutocompleteArrayInput/>
          </ReferenceArrayInput>
          <ReferenceInput label="Court" source="courtId" reference="Court" filterToQuery={searchText => ({ search: searchText })}>
            <AutocompleteInput optionText="name" />
          </ReferenceInput>           
          <ReferenceInput label="Game" source="gameId" reference="Game" filterToQuery={searchText => (searchText ? { _id: searchText } : {})}>
            <AutocompleteInput optionText="_id" />
          </ReferenceInput> 
          <TextInput source="notes" multiline />          
      </SimpleForm>
  </Edit>
);


export const EventLogCreate = (props) => (
  <Create {...props}>
      <SimpleForm>
          {/* <TextInput source="type" validate={required()} /> */}
          <SelectInput source="type" choices={[
            { id: 'game_played', name: 'Game Played' },
            // { id: 'lifestyle', name: 'Lifestyle' },
            // { id: 'photography', name: 'Photography' },
          ]} validate={required()} />          
          <TextInput disabled source="source" initialValue="admin"/>
          <DateInput source="date" />          
          <ReferenceArrayInput label="Players" source="playerIds" reference="User">
            <AutocompleteArrayInput/>
          </ReferenceArrayInput>
          <ReferenceInput label="Court" source="courtId" reference="Court" filterToQuery={searchText => ({ search: searchText })}>
            <AutocompleteInput optionText="name" />
          </ReferenceInput>          
          <ReferenceInput label="Game" source="gameId" reference="Game" filterToQuery={searchText => (searchText ? { _id: searchText } : {})}>
            <AutocompleteInput optionText="_id" />
          </ReferenceInput>          
          <TextInput source="notes" multiline />          
      </SimpleForm>
  </Create>
);


const App = () => {

  const [dataProvider, setDataProvider] = useState();

  useEffect(() => {
    buildGraphQLProvider({ 
      client, 
      // clientOptions: { uri: 'http://localhost:4000/graphql' },
      buildQuery,
      resolveIntrospection: introspection,
      introspection: {
          operationNames: introspectionOperationNames,
          exclude: undefined,
          // include: undefined,
      },      
    })
    .then(_dataProvider => {
      // console.log("data provider", _dataProvider );
      // setDataProvider(_dataProvider)
      setDataProvider(() => _dataProvider);
    });
  },[]);

  if (!dataProvider) {
    return null;
  }
  // console.log("dataProv", dataProvider);

  return (
    <Admin theme={theme} dataProvider={dataProvider} authProvider={spinAuthProvider}>    
      <Resource name="User" {...users} /> 
      <Resource name="Game" {...games} />
      <Resource name="Chat" {...chats} />        
      <Resource name="Court" {...courts} />
      <Resource name="League" list={LeagueList} edit={LeagueEdit} show={LeagueShow}/> 
      <Resource name="Coupon" {...coupons} />
      <Resource name="EventLog" options={{ label: "Event Logs" }} list={EventLogList} edit={EventLogEdit} create={EventLogCreate}/>
      <Resource name="GeographicArea" options={{ label: "Geographic Area" }} {...geographicAreas}/>
      <Resource name="Season" {...seasons} /> 
      <Resource name="ChatMessage" options={{ label: "Chat Messages" }} {...chatMessages} /> 
      <Resource name="SkillAssesment" options={{ label: "Skill Assesments" }} {...skillAssesments} />
      <Resource name="PlayerProgressLog" options={{ label: "Progress Logs" }} {...playerProgressLogs}/>
      <Resource name="Payment" {...payments} />      
    </Admin>
  );
}

export default App;