import * as React from "react";
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { linkToRecord, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
  container: {
    bottom: 0
    // position: "fixed" // remove this so we can apply flex design
  },
  lost: {
    color: '#aaa'
  },
  tb: {
    margin: '0 0 0 5px'
  }
})); 

const Score = ({ source, record = {}, ...rest }) => { 
  ///console.log(rest);
  const { chat } = rest;
  const classes = useStyles();

  const score = record.score
  const { comments = [], sets = [], tbs = [] } = score;

  const users = record.users.filter(u => u.plays === true);

  if (!score || !score.system) {
    return null;
  }
  return (
    <Grid container xs={12} spacing={3}>
      <Grid container item xs={12} spacing={3}>
        {score.system === 'practice' && (
          <Grid container item >
            <span>Practice:</span>
          </Grid>
        )}
      </Grid>        
      <Grid container xs={12} item>
        <Grid className="testing" container item xs={6} spacing={1}>
          {users.map((user, i) => (
            <Grid
              container
              item
              alignItems='center'
              spacing={2}
              key={i}              
            >
              <Grid item>
                <Avatar src={user.data.avatar}/>
              </Grid>
              
              <Grid item>
                <span className={ score.winnerId && score.winnerId !== user.data._id ? classes.lost : null} size='16px'>{user.data.name}</span>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid container item xs>
          {sets.map((set, i) => {
            if(set.length === 0 || !set[0] && !set[1]) return null

            return (
              <Grid key={i}
                container
                item
                alignItems='center'
                justify='flex-start'
                xs
              >
                {set.map((val, i2) => (
                  <Grid
                    container
                    item
                    // spacing={3}
                    key={i2}                    
                  >
                    <span
                      size='22px'
                      className={
                        ((set[0] < set[1] && i2 === 0) || (set[0] > set[1] && i2 === 1)) ? classes.lost : null
                      }
                    >
                      {val}
                    </span>

                    {set.includes(7) && set[0] + set[1] === 13 && (
                      <Grid item>
                        <sup
                          className={
                            ((set[0] < set[1] && i2 === 0) || (set[0] > set[1] && i2 === 1)) ? classes.lost : classes.tbs
                          }
                        >
                          {typeof tbs[i][i2] === 'number' ? tbs[i][i2].toString() : ''}
                        </sup>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      
      <Grid container item xs={12} spacing={3}>
        {comments.length > 0 && comments.map((c, i) => {
          if(c.length === 0) return null

          return (
            <Grid
              container
              item            
              alignItems='flex-start'
              key={i}
            >
              <span><span>{users[i].data.firstName}:</span> {c}</span>
            </Grid>
          )
        })}
      </Grid>        
    </Grid>
  )
}

Score.defaultProps = {
  label: 'Score',
  addLabel: true,
};

Score.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default Score;