import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { useMutation, useNotify, useRedirect, useRefresh, Button as AdminButton } from 'react-admin';
import { fade } from '@material-ui/core/styles/colorManipulator';

const SuspendUserButton = ({ record }) => {
// const SuspendUserButton = ({ record }) => {       
    const [showModal, setShowModal] = useState(false); 
    // console.log("Player is ", record)
    // console.log("Game is ", game.id)
    const notify = useNotify();
    const refresh = useRefresh();
    
    const isSuspend = !record.flagged;
    const action =  isSuspend ? 'Suspend' : 'Unsuspend';


    // const redirect = useRedirect();
    const [approve, { loading }] = useMutation(
        {
            type: "update",
            resource: 'User',
            payload: { 
                id: record.id,
                data: { 
                    flagged: isSuspend ? true : false,
                    hidden: isSuspend ? true : false,
                    devices: [],
                    notificationSettings: {
                        unsubscribe: isSuspend ? true : false,
                        messages: {
                            email: isSuspend ? false : true,
                            push: isSuspend ? false : true,
                        },
                        nearby: {
                            email: isSuspend ? false : true,
                            push: isSuspend ? false : true,
                        },
                    },
                },
            },
        },
        {
            onSuccess: ({ data }) => {
                // redirect(`/eventlog/${data.id}`);
                setShowModal(false);
                notify(`${action}ed user ${record._id}`);
                refresh();
                
            },
            onFailure: (error) => notify(`Report error: ${error.message}`, 'warning'),
        }
    );
    return (
        <>
            <AdminButton size="large" label={`${action.toUpperCase()}`} color="secondary" onClick={() => setShowModal(true)} disabled={loading} />
            <Dialog
                open={showModal}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{action} User (Flag)</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {action} {record.name}?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setShowModal(false)} color="secondary">
                    Cancel
                </Button>
                <Button onClick={approve} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SuspendUserButton;