import * as React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';


const useQuickFilterStyles = makeStyles(theme => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));
const QuickFilter = ({ label }) => {
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={label} />;
};

export default QuickFilter;