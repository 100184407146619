import React, { useState } from "react";
import { useNotify, useRedirect, useRefresh, Button as AdminButton } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Button, Select, MenuItem, InputLabel, Divider } from '@material-ui/core';
import { useMutation as useApolloMutation } from '@apollo/client';
import REMOVE_PLAYER from "./graphql/mutations/removePlayerMutation";
import client from "./graphql/client";


const RemovePlayerButton = ({ game, ...rest }) => {

    const [showModal, setShowModal] = useState(false);
    //const [submitClicked, setSubmitClicked] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const { _id: gameId, owner: gameOwner } = game;
    const { name, _id: removedUserId } = rest?.record?.data ?? {};


    const [removePlayer, { loading: removePlayerLoading }] = useApolloMutation(REMOVE_PLAYER, {
        onCompleted: (data) => {
            setShowModal(false);
            notify(` ${name} has been removed from game`);
            refresh();
        },
        onError: (error) => notify(`Report error: ${error.message}`, 'warning'),
        client,
        variables: {
            gameId,
            removedUserId
        }
    });


    return (
        removedUserId === gameOwner ?
            null :
            <>
                <AdminButton
                    size="large"
                    label="Remove Player"
                    onClick={
                        () => {
                            setShowModal(true);
                            //setSubmitClicked(false);
                        }
                    }
                />
                <Dialog
                    open={showModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Remove player from game</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This action will remove {name} from the game.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={
                                () => {
                                    setShowModal(false);
                                }}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={
                                () => {
                                    removePlayer();
                                    setShowModal(false);
                                }
                            }
                            color="primary"
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
    );
}

export default RemovePlayerButton;