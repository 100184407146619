import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { useMutation, useNotify, useRefresh, Button as AdminButton } from 'react-admin';
import { gameStatus } from "./constants";
import axios from 'axios';
const CancelGame = ({ coordinates, show, onClose, onSelectArea, onSelect }) => {
    const [showModal, setShowModal] = useState(false); 
    const [loading, setLoading] = useState(true);
    const [places, setPlaces] = useState([]);
    const fetchPoints = async (coordinates) => {
        const res = await axios.get(`https://findthatpostcode.uk/points/${coordinates.lat},${coordinates.lng}.json`)
        console.log('points are', res.data);
        setPlaces(res.data.included || [])

    }

    const fetchGeoJson = async (code) => {
        const res = await axios.get(`https://findthatpostcode.uk//areas/${code}.geojson`)
        console.log('geojson data', res.data);
        onSelect(res.data.features[0]);
    }
    
    useEffect(() => {
        coordinates && fetchPoints(coordinates);
    }, [coordinates]);
    return (
        <>
            <AdminButton size="large" label="Cancel Game" onClick={() => setShowModal(true)} disabled={loading} />
            <Dialog
                open={show}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Cancel Game</DialogTitle>
                <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
                    dsf
                </DialogContentText> */}
                {places.filter(s => s.attributes && s.attributes.name).map((s, i) => <Button key={`pl-${i}`} onClick={() => fetchGeoJson(s.attributes.code)} color="primary">
                    {s.attributes.name} {s.attributes.code}
                </Button>)}
                </DialogContent>
                <DialogActions>
                <Button onClick={() => onClose(false)} color="primary">
                    No
                </Button>
                <Button onClick={() => onClose(false)} color="primary" autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CancelGame;