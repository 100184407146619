import * as React from "react";
import { 
    BooleanField,
    ArrayField,
    Datagrid,
    ImageField,
    DateField,
    FunctionField,
    TextField,
    ReferenceManyField,
    ReferenceField,
    Tab,
    TabbedShowLayout,
    ShowView,
    ShowController,
    useRefresh,
    Toolbar
   } from 'react-admin';
import { JsonField } from "react-admin-json-view";
import AvatarField from "../AvatarField";
import GameStatusChanger from "../GameStatusChanger";
import { timeOptions, gameStatus } from "../constants";
import GrantWalkoverButton from "../GrantWalkoverButton";
import MarkRetirement from "../MarkRetirement";
import Message from "../message";
import Score from "../Score";
import GameScoreEdit from "./GameScoreEdit";
import RemovePlayerButton from "../RemovePlayerButton";

const GameShow = props => {
  return <ShowController {...props}>
    {controllerProps =>
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>         
          <Tab label="summary">
            <ReferenceField link="show" label="Owner" source="owner" reference="User">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField link="show" label="Season" source="seasonId" reference="Season">
              <FunctionField
                label="Season"
                render={record => `${record.league.name} - ${record.name}`}
              />
            </ReferenceField>
            <TextField source="division"/>
            
            <DateField label="Created" source="createdAt" showTime={true} options={timeOptions} locales="en-GB" />
            <DateField source="startTime" showTime={true} options={timeOptions} locales="en-GB" />
            <DateField source="endTime" showTime={true} options={timeOptions} locales="en-GB" />            
            <TextField label="Location" source="location.text" />          
            <TextField source="status" />
            <TextField source="description" />
            <Score label="Score" source="score" {...props}/>
            <GameScoreEdit label="Score" source="score" {...props}/>
            <ArrayField label="Players" source="users">
              <Datagrid>
                <AvatarField label="Avatar" source="data.avatar"/>
                <TextField label="Name" source="data.name" />
                <TextField label="Id" source="data._id" />
                <BooleanField source="plays" />
                <BooleanField source="invited" />
                <GrantWalkoverButton game={controllerProps.record} {...props}/>
                <MarkRetirement game={controllerProps.record} {...props}/>
                {
                  !controllerProps?.record?.leagueId && 
                    <RemovePlayerButton game={controllerProps.record} {...props} />
                }
              </Datagrid>
            </ArrayField>   

              
            <BooleanField source="hidden" />
            <BooleanField source="full" />            
            <TextField source="skillLevel" label="Skill"/>
            <TextField source="gender" label="Gender"/>
            <TextField source="flexibleDates" label="Flexible"/>
            <TextField source="source" />          
            <TextField source="courtNo" label="Court" />
            <BooleanField source="booked" />
            <BooleanField source="featured" />
            <Toolbar>
              {(controllerProps.record && controllerProps.record.status !== gameStatus.CANCELLED) &&
                <GameStatusChanger game={controllerProps.record} newStatus={gameStatus.CANCELLED} {...props}/>
              }
              {(controllerProps.record && !controllerProps.record.seasonId && controllerProps.record.status !== gameStatus.DIDNT_PLAY) &&
                <GameStatusChanger game={controllerProps.record} newStatus={gameStatus.DIDNT_PLAY} {...props}/>
              }
            </Toolbar>
          </Tab>
          <Tab label="Messages"> 
            <ReferenceManyField
              reference="messages"
              target="id"
              label="Messages"            
            > 
              <Datagrid>
                <Message label="Messages" game={controllerProps.record} source="createdAt" {...props}/>
              </Datagrid>
              {/* <Messages game={controllerProps.record}/> */}
            </ReferenceManyField>
          </Tab>           
        </TabbedShowLayout>
      </ShowView>
    }
  </ShowController>
}
  
export default GameShow;
  