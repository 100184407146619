import * as React from "react";
import { 
    SimpleForm,
    ReferenceArrayInput,
    TextInput,
    Edit,
    TabbedForm,
    Toolbar,
    TextField,
    NumberInput,
    BooleanInput,
    SaveButton
   } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteUserButton from "../DeleteUserButton";
import { Card as MUCard, CardHeader } from "@material-ui/core";

const Card = (props) => (
  <MUCard style={{ padding: 10, width: "auto" }} {...props} />
);

const CustomUserToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteUserButton/>
  </Toolbar>
);

const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },    
});
export const PlayerProgressLogEdit = (props) => (
  <Edit {...props}>
      <SimpleForm toolbar={<CustomUserToolbar />}>
          <TextInput disabled label="Id" source="id" />    
          {/* <TextInput source="firstName" />
          <TextInput source="avatar" />        
          <TextInput source="email" />                 
          <TextInput source="mobile" />
          <TextField label="Location" source="location.text" /> */}
          <NumberInput label="League Entry Skill" source="previousValue" />
          <NumberInput source="newValue" />          
          <NumberInput source="finalOverride" />
          {/* <DateField source="lastOnline" showTime={true} options={timeOptions} locales="en-GB" /> */}
          <BooleanInput disabled source="verify"/>
         {/* <Card variant="outlined">
            <CardHeader title="Membership" />
         </Card>
          <Card variant="outlined">
            <CardHeader title="Notification Settings" />
            <Card variant="outlined">
              <CardHeader subheader="Messages" />
              <BooleanInput source="notificationSettings.messages.email" label="Email"/>
              <BooleanInput source="notificationSettings.messages.push" label="Push"/>
              <BooleanInput source="notificationSettings.messages.required" label="Required"/>
            </Card>
            <Card variant="outlined">
              <CardHeader subheader="Nearby" />
              <BooleanInput source="notificationSettings.nearby.email" label="Email"/>
              <BooleanInput source="notificationSettings.nearby.push" label="Push"/>
              <BooleanInput source="notificationSettings.nearby.required" label="Required"/>
            </Card>
            <BooleanInput source="notificationSettings.unsubscribe" label="Unsubscribe"/>
          </Card> */}
      </SimpleForm>
  </Edit>
);
  
  export default PlayerProgressLogEdit;
  