import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    FunctionField,
    EmailField,
    BooleanField,
    Filter,
    TextInput
} from 'react-admin';
import SpinPagination from "../components/SpinPagination";
import QuickFilter from "../components/QuickFilter";
import { timeOptions } from "../constants";
import { handleRowClick } from "../libs/handleRowClick";
import { convertCurrencyToSymbol } from "../libs/convertCurrencyToSymbol";
import { paymentPaidDataPipeline } from "../libs/paymentPaidDataPipeline";

const PaymentPromoCodeFilter = props => (
    <Filter {...props}>
        <QuickFilter label="Payment Completed" source="paymentComplete" defaultValue={true} />
    </Filter>
);

const PaymentPromoCodeList = props => {
    const resource = {
        ...(props.source && { resource: props.source })
    }

    return (
        <List {...props} {...resource} filters={<PaymentPromoCodeFilter />} pagination={<SpinPagination />} perPage={50} exporter={false}>
            <Datagrid isRowSelectable={() => false} rowClick={(id, resource, record) => handleRowClick(record.couponId, 'Coupon')}>
                <TextField source="coupon.code" label="Promo Code" />
                <TextField source="source" />
                <FunctionField
                    label="Name"
                    render={record => `${record.firstName} ${record.lastName}`}
                />;
                <EmailField source="email" />
                <FunctionField
                    label="Amount Paid"
                    render={
                        record => {
                            const payment = paymentPaidDataPipeline(record);
                            const paidData = payment?.paidData;
                            const { amount, currency } = paidData?.data?.object ?? {};
                            const formattedAmount = amount ? (amount / 100).toFixed(2) : '0.00';
                            return `${convertCurrencyToSymbol(currency)} ${formattedAmount}`;
                        }
                    }
                />
                <FunctionField
                    label="Discount"
                    render={
                        record => {
                            const payment = paymentPaidDataPipeline(record);
                            const paidData = payment?.paidData;
                            const { currency } = paidData?.data?.object ?? {};
                            const discount = record?.coupon?.discount;
                            const formattedDiscount =  discount ? discount.toFixed(2) : '0.00';
                            return `${convertCurrencyToSymbol(currency)} ${formattedDiscount}`;
                        }
                    }
                />
                <FunctionField
                    label="Campaign"
                    render={
                        record => {
                            const attribution = record?.attribution;
                            if (!attribution) return '-';
                            const campaign = attribution?.campaign ?? attribution?.utm_campaign;
                            return campaign ?? '-';
                        }
                    }
                />
                <BooleanField label="Payment Complete" source="paymentComplete" />
                <DateField source="createdAt" label="Redemption Date" showTime={true} options={timeOptions} locales="en-GB" />
            </Datagrid>
        </List>
    );
}

export default PaymentPromoCodeList;