import React, { useState, useRef, useEffect } from "react";
import { required } from "react-admin";
import capitalize from "@material-ui/core/utils/capitalize";

import { Card as MUCard } from "@material-ui/core";
import {
  Autocomplete,
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api";
import UkGeojsonPicker from "../../UkGeojsonPicker";
const Card = (props) => (
  <MUCard style={{ padding: 10, width: "auto" }} {...props} />
);

const containerStyle = {
  width: "100%",
  height: "500px",
};

const center = { lat: 51.538421, lng: -0.226627 }


const LocaleInput = ({ geoJsonData, label, isEditable, onAreaSelection }) => {
  const [selectedPoint, setSelectedPoint] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBLtdj2rqFHqmfVYonkMMbBa8Mxdasv2c4",
    libraries: ["places"],
  });
  const autoCompleteRef = useRef();
  const handleGeoJsonData = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.data.forEach(function (feature) {
      map.data.remove(feature);
    });
    geoJsonData && map.data.addGeoJson(geoJsonData);
    map.data.forEach(function (feature) {
      feature.getGeometry().forEachLatLng(function (latlng) {
        bounds.extend(latlng);
      });
    });
    map.fitBounds(bounds);
  };
  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
    geoJsonData && handleGeoJsonData(map);
  }, []);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const [map, setMap] = React.useState(null);

  const handleSelectGeoJson = (finalGeoJson) => {
    setSelectedPoint(false);
    const bounds = new window.google.maps.LatLngBounds();
    map.data.forEach(function (feature) {
      map.data.remove(feature);
    });
    finalGeoJson && map.data.addGeoJson(finalGeoJson);
    onAreaSelection && onAreaSelection(finalGeoJson);
    map.data.forEach(function (feature) {
      feature.getGeometry().forEachLatLng(function (latlng) {
        bounds.extend(latlng);
      });
    });
    map.fitBounds(bounds);
  };
  const onPlaceChanged = (s) => {
    if (autoCompleteRef && autoCompleteRef.current !== null) {
      const place = autoCompleteRef.current.getPlace();
      if (place && place.geometry) {
        const coordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setSelectedPoint(coordinates);
      } else {
        alert("invalid location");
      }
    } else {
      console.log("maps is not loaded yet!");
    }
  };
  const onAutoCompleteLoad = (autocomplete) => {
    autoCompleteRef.current = autocomplete;
  };
  useEffect(() => {
    map && geoJsonData && handleGeoJsonData(map);
  }, [geoJsonData]);
  return (
    <Card variant="outlined">
      <h3>{label}</h3>
      {isLoaded && (
        <>
          {isEditable && (
            <Autocomplete
              onLoad={onAutoCompleteLoad}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                // style={{width: '100%'}}
                className="autocomplete-search-input"
                placeholder="Search place"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `100%`,
                  height: `32px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                }}
              />
            </Autocomplete>
          )}
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {/* Child components, such as markers, info windows, etc. */}
            <></>
          </GoogleMap>
        </>
      )}
      {selectedPoint && (
        <UkGeojsonPicker
          show={true}
          coordinates={selectedPoint}
          onClose={() => setSelectedPoint(null)}
          onSelect={(finalGeoJson) => handleSelectGeoJson(finalGeoJson)}
        />
      )}
    </Card>
  );
};
export default ({
  source,
  label,
  extraFields = [],
  geoJsonData,
  isEditable,
  onAreaSelection,
}) => (
  <LocaleInput
    label={label || capitalize(source.split("_").join(" "))}
    source={`${source}`}
    extraFields={extraFields}
    geoJsonData={geoJsonData}
    isEditable={isEditable}
    onAreaSelection={onAreaSelection}
    required={required()}
  />
);
