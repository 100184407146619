import * as React from "react";
import { 
    SimpleForm,
    ReferenceArrayInput,
    TextInput,
    Edit,
    TabbedForm,
    Toolbar,
    TextField,
    NumberInput,
    BooleanInput,
    SaveButton
   } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteUserButton from "../DeleteUserButton";
  
const CustomUserToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteUserButton/>
  </Toolbar>
);

const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },    
});

export const GameEdit = (props) => (
  <Edit {...props}>
      <SimpleForm toolbar={<CustomUserToolbar />}>
          <BooleanInput source="featured"/>
      </SimpleForm>
  </Edit>
);
  
  export default GameEdit;
  