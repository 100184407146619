import * as React from "react";
import { 
    BooleanField,
    NumberField,
    EmailField,
    SelectInput,
    ReferenceField,
    ImageField,
    DateField,
    Toolbar,
    TextField,
    TextInput,
    Filter,
    Datagrid,
    List
   } from 'react-admin';
import { JsonField } from "react-admin-json-view";
import AvatarField from "../AvatarField";
import CustomDataGridBody from "../components/CustomDataGridBody";
import QuickFilter from "../components/QuickFilter";
import SpinPagination from "../components/SpinPagination";
import { timeOptions } from "../constants";
import PlayerFilter from "../components/filters/PlayerFilter";

const ListFilter = props => (
  <Filter {...props}>
      <QuickFilter source="verify" label="Can Verify" defaultValue={true} />
      {/*<TextInput label="User Id" source="userId" defaultValue={null}  />*/}
      <PlayerFilter label="User" sourceName="User" source="userId"/>
      <SelectInput
            choices={[
                { id: 'Pending', name: 'Pending' },
                { id: 'Completed', name: 'Completed' },
            ]}
            source="status"
        />       
  </Filter>
);

export const PlayerProgressLogList = props => (
  <List {...props} filters={<ListFilter/>} pagination={<SpinPagination />} perPage={50} sort={{ field: 'createdAt', order: 'DESC' }} exporter={false}>
      {/* <Datagrid rowClick="show" body={<CustomDataGridBody resourceType="playerProgressLog"/>}> */}
      <Datagrid rowClick="show">
        <ReferenceField link="show" label="Player" source="userId" reference="User">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField link="show" label="Season" source="seasonId" reference="Season">
          <TextField source="name" />
        </ReferenceField> 
        <NumberField label="Percent Won" source="percentageOfGamesWon" options={{ style: 'percent' }} />
        <NumberField label="Self Assesed" source="previousValue" options={{ maximumFractionDigits: 2 }} />
        <NumberField label="Suggested" source="newValue" options={{ maximumFractionDigits: 2 }} />
        <NumberField label="Final" source="finalOverride" options={{ maximumFractionDigits: 2 }} />
        <BooleanField label="Can Verify" source="verify" />
        {/* <NumberField  source="unbalancedWins" />
        <NumberField  source="unbalancedLosses" />
        <NumberField  source="balancedMatches" /> */}
        <TextField source="status" />
        <DateField label="Created" source="createdAt" showTime={true} options={timeOptions} locales="en-GB" />
        <DateField label="Updated" source="updatedAt" showTime={true} options={timeOptions} locales="en-GB" />


        {/* <TextField source="id" /> */}
      </Datagrid>
  </List>
);
  
export default PlayerProgressLogList;
  