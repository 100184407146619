import React from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  TextInput
} from "react-admin";
import {
  playerInputText,
  playerMatchSuggestion,
  playerOptionText,
  nameFilterToQuery,
} from "./PlayerFilterOptions";

const PlayerFilter = (props) => {
  const sourceName = props && props.sourceName ?
    props.sourceName :
    "Player";

  return (
    <>
      <TextInput
        {...props}
        label={`${sourceName} Id`}
        style={{ marginRight: "20px" }}
      />
      <ReferenceInput {...props} label={`${sourceName} Name`} reference="User" filterToQuery={nameFilterToQuery}>
        <AutocompleteInput
          optionText={playerOptionText}
          inputText={playerInputText}
          matchSuggestion={playerMatchSuggestion} />
      </ReferenceInput>
    </>
  );
};

export default PlayerFilter;