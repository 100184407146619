// import PeopleIcon from '@material-ui/icons/People';
// import SeasonCreate from './UserCreate';
import PlayerProgressLogEdit from './PlayerProgressLogEdit';
import PlayerProgressLogList from './PlayerProgressLogList';
import PlayerProgressLogShow from './PlayerProgressLogShow';
// import UserShow from './UserShow';
// import UserList from './UserList';
// import SeasonShow from './UserShow';

export default {
    // list: PlayerProgressLogList,
    list: PlayerProgressLogList,
    // create: UserCreate,
    edit: PlayerProgressLogEdit,
    show: PlayerProgressLogShow,
    // icon: PeopleIcon,
};