import * as React from "react";
import {
  Toolbar,
  TextField,
  TextInput,
  NumberInput,
  SaveButton,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  Edit,
  required,
  SelectInput
} from "react-admin";
import { JsonField } from "react-admin-json-view";
import { Card as MUCard, CardHeader, CardContent } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MapGeoJSONExtended from "../components/GoogleMaps/MapGeoJSONExtended";
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import GeographicAreaSearchUK from "../components/GeographicAreaSearchUK";

const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },    
});

const Card = (props) => (
  <MUCard style={{ padding: 10, width: "auto" }} {...props} />
);
const CustomUserToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {/* <DeleteUserButton/> */}
  </Toolbar>
);
const GeographicAreaEdit = (props) => {
  //  const transform = data => new Promise((resolve, reject) => {
  //    console.log('data are', data);
  //   // reject('hahahaha');
  //  });
  return (
    <Edit {...props} toolbar={<CustomUserToolbar />}>
      <SimpleForm onSubmit={() => {
        console.log('submitting', "sdf");
      }}>
        <TextField source="id" />
        {/* <NumberInput source="level"/> */}
         <TextInput source="slug" />
         <ReferenceInput label="Parent" source="parent" reference="GeographicArea" filterToQuery={searchText => ({ search: searchText })}>
            <AutocompleteInput optionText={(rec) => {
              return `${rec.name.en_GB} (${rec.slug})`;
            }} />
          </ReferenceInput>
          <SelectInput source="type" choices={['COUNTRY', 'CITY', 'STATE', 'PLACE'].map(s => ({id: s, name: s}))} validate={required()} />  
          <Card variant="outlined">
            {/* <CardHeader title="Name" /> */}
            <CardHeader subheader="Name" />
            <Card variant="outlined">
              <TextInput source="name.en_GB" label="en_GB"/>
              <TextInput source="name.en_US" label="en_US"/>
              <TextInput source="name.es" label="es"/>
            </Card>
          </Card>
        <MapGeoJSONExtended source="geometry"/>
      </SimpleForm>
    </Edit>
  );
};

export default GeographicAreaEdit;
