import React from "react";
import {
    ReferenceManyField,
    Datagrid,
    TextField,
    FunctionField,
    DateField,
    Button as AdminButton
} from "react-admin";
import { Link, useHistory } from 'react-router-dom';

const UserSeasons = props => {
    const user = props.record;

    return (
        <ReferenceManyField {...props} reference="Season" target="byUser" >
            <Datagrid>
                <TextField source="_id" label="Id" />
                <FunctionField
                    label="League"
                    render={record => (
                        <Link to={`/League/${record?.league?._id}/show`}>
                            {record.league.name}
                        </Link>
                    )}
                />
                <TextField source="name" label="Name" />
                <DateField source="start" label="Start Date" />
                <DateField source="end" label="End Date" />
                <TextField source="status" label="Status" />
                <FunctionField
                    render={
                        record => {
                            const userId = user._id;
                            const league = record.league._id;
                            const season = record._id;
                            const divisionUrl = `${process.env.REACT_APP_LEAGUE_ADMIN_URL}/league/${league}/season/${season}/divisions#${userId}`;
                            return (
                                <AdminButton
                                    size="large"
                                    label="View divisions"
                                    onClick={() => window.open(divisionUrl, "_blank")}
                                />)
                        }
                    }
                />
            </Datagrid>
        </ReferenceManyField>
    )
};

export default UserSeasons;