// in LatLongInput.js
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useInput, required } from "react-admin";
import { useForm } from "react-final-form";
import MapGeoJSON from "./MapGeoJSON";
import { Card as MUCard } from "@material-ui/core";
const Card = (props) => (
  <MUCard style={{ padding: 10, width: "auto" }} {...props} />
);
const BoundedField = (props) => {
  const {
    input: { name, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const [isValid, setIsValid] = useState(true);
  const [internalValue, setInternalValue] = useState("");
  const [geoJsonForMap, setGeoJsonForMap] = useState(value);
  const form = useForm();
  const handleFinalGeometry = (geometry) => {
    form.change("geometry", geometry);
    setGeoJsonForMap(geometry);
  }
  const _onChange = (s) => {
    setInternalValue(s.target.value);
    try {
      let geoJson = JSON.parse(s.target.value);
      setIsValid(true);

      if (geoJson && Array.isArray(geoJson)) {
        // check if feature array
        geoJson = geoJson[0];
      }
      if (
        geoJson &&
        geoJson.type &&
        geoJson.features &&
        Array.isArray(geoJson.features)
      ) {
        // check if has features
        geoJson = geoJson.features[0];
      }

      if (geoJson && geoJson.geometry) {
        handleFinalGeometry(geoJson.geometry);
      } else {
        console.log("failed to get geometry");
      }
    } catch (e) {
      setIsValid(false);
      console.log(e);
    }
  };
  const geoJsonData = geoJsonForMap && geoJsonForMap.coordinates && {
              type: "Feature",
              geometry: {
                type: geoJsonForMap.type,
                coordinates: geoJsonForMap.coordinates,
              },
              properties: {
                name: "ramramram",
              },
            };
  return (
    <>
      <Card style={{ width: "calc(100vw - 300px)" }}>
          <MapGeoJSON
            isEditable
            source="location"
            label="Location"
            extraFields={[]}
            geoJsonData={geoJsonData}
            onAreaSelection={(selectedArea) => {
                selectedArea && selectedArea.geometry && handleFinalGeometry(selectedArea.geometry);
            }}
          />
        </Card>
      <TextField
        multiline
        rows={5}
        style={{ width: 500 }}
        // validate={_onChange()}
        name={name}
        label={props.label}
        error={!isValid}
        helperText={touched && error}
        // required={isRequired}
        {...rest}
        value={internalValue}
        onChange={_onChange}
      />
    </>
  );
};
const MapGeoJSONExtended = (props) => {
  return (
    <span>
      <BoundedField label="GeoJSON text" validate={required()} {...props} />
    </span>
  );
};
export default MapGeoJSONExtended;
