import React, { useState, useRef } from "react";
import { useInput, required, TextInput } from "react-admin";
import { useForm } from "react-final-form";
import capitalize from "@material-ui/core/utils/capitalize";

import MapPicker from "../../libs/LocationPickerLib";
import { Card as MUCard } from "@material-ui/core";
import { Autocomplete } from "@react-google-maps/api";
import GoogleScriptLoaded from "./GoogleScriptLoaded";

const Card = (props) => (
  <MUCard style={{ padding: 10, width: "auto" }} {...props} />
);

const DefaultLocation = { lat: 51.538421, lng: -0.226627 };
const DefaultZoom = 10;

const LocaleInput = (props) => {
  const [zoom, setZoom] = useState(DefaultZoom);
  const autoCompleteRef = useRef();
  const form = useForm();
  const {
    input: { value },
    // meta: { touched, error },
    // isRequired
  } = useInput(props);
  const { source, extraFields = [], label = "Location" } = props;
  const [defaultLocation, setDefaultLocation]  = useState(
          value && value.coordinates && Array.isArray(value.coordinates)
            ? { lat: value.coordinates[1], lng: value.coordinates[0] }
            : DefaultLocation);
  const handleChangeLocation = (lat, lng) => {
    const currentLocation = form.getState().values.location;
    form.change("location", {
      ...currentLocation,
      coordinates: [lng, lat],
    });
  };

  const handleChangeZoom = (newZoom) => {
    setZoom(newZoom);
  };
  const onPlaceChanged = (s) => {
    if (autoCompleteRef && autoCompleteRef.current !== null) {
      const place = autoCompleteRef.current.getPlace();
      if(place && place.geometry) {
        const coordinates = { lat: place.geometry.location.lat(), lng : place.geometry.location.lng()};
        console.log('coord', coordinates);
        setDefaultLocation(coordinates);
        handleChangeLocation(coordinates.lat, coordinates.lng);
      } else {
        alert('invalid location');
      }
    } else {
      console.log('maps is not loaded yet!')
    }
  }
  const onAutoCompleteLoad = (autocomplete) => {
    autoCompleteRef.current = autocomplete;
  }

  return (
    <Card variant="outlined">
      <h3>{label}</h3>
      {extraFields.map((field) => (
        <TextInput
          validate={field.required ? required() : null}
          key={`${source}.${field.source}`}
          label={field.label}
          source={`${source}.${field.source}`
        }
        />
      ))}
      <TextInput
        source="location.country.name.en_GB"
        label="Country"
        disabled
      />
      <TextInput
        source="location.city.name.en_GB"
        label="City"
        disabled
      />
      <TextInput
        source="location.state.name.en_GB"
        label="State"
        disabled
      />
      <TextInput
        source="location.place.name.en_GB"
        label="Place"
        disabled
      />     
      <TextInput
        source="location.coordinates"
        label="Coordinates"
        disabled
        validate={required()}
      />
      <GoogleScriptLoaded>
        <Autocomplete
        onLoad={onAutoCompleteLoad}
        onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            // style={{width: '100%'}}
            className="autocomplete-search-input"
            placeholder="Search place"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `100%`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
            }}
          />
        </Autocomplete>
      </GoogleScriptLoaded>
      <MapPicker
        defaultLocation={defaultLocation}
        zoom={zoom}
        style={{ height: "700px" }}
        onChangeLocation={handleChangeLocation}
        onChangeZoom={handleChangeZoom}
        apiKey="AIzaSyBLtdj2rqFHqmfVYonkMMbBa8Mxdasv2c4"
      />
    </Card>
  );
};
export default ({ source, label, extraFields = [] }) => (
  <LocaleInput
    label={label || capitalize(source.split("_").join(" "))}
    source={`${source}`}
    extraFields={extraFields}
    required={required()}
  />
);
