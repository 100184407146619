import * as React from "react";
import { 
  Create,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  TextInput
} from 'react-admin';

export const CouponCreate = (props) => (
  <Create {...props}>
      <SimpleForm>
          <TextInput source="code" />
          <NumberInput source="discount" />
          <DateInput source="expiresAt"/>
          <NumberInput source="maxRedeems" />
          <NumberInput source="maxRedeemsPerUser" />          
          <ReferenceInput label="User" source="userId" reference="User">
            <AutocompleteInput optionText="name" />
          </ReferenceInput>            
      </SimpleForm>
  </Create>
);
  
export default CouponCreate;
  