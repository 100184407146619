import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
    // palette: {
    //     secondary: {
    //       // light: '#33df9d',
    //       // main: '#00d885',
    //       // dark: '#00975d',
    //       // contrastText: '#fff',
    //       main: "#152832",
    //       light: "rgb(67, 83, 91)",
    //       dark: "rgb(14, 28, 35)",
    //       contrastText: "#fff",
    //     },
    //     primary: {
    //       // light: '#43535b',
    //       // main: '#152832',
    //       // dark: '#0e1c23',
    //       // contrastText: '#000',
    //       main: "#00d885",
    //       light: "rgb(51, 223, 157)",
    //       dark: "rgb(0, 151, 93)",
    //       contrastText: "rgba(0, 0, 0, 0.87)",
    //     },
    //     error: red,
    //     contrastThreshold: 3,
    //     tonalOffset: 0.2,
    // },
    palette: {
      "tonalOffset": 0.2,
      "background": { "paper": "#fff", "default": "#fafafa" },
      "contrastThreshold": 3,
      "grey": {
        "50": "#fafafa",
        "100": "#f5f5f5",
        "200": "#eeeeee",
        "300": "#e0e0e0",
        "400": "#bdbdbd",
        "500": "#9e9e9e",
        "600": "#757575",
        "700": "#616161",
        "800": "#424242",
        "900": "#212121",
        "A700": "#616161",
        "A100": "#d5d5d5",
        "A400": "#303030",
        "A200": "#aaaaaa"
      },
      "text": {
        "primary": "rgba(0, 0, 0, 0.87)",
        "secondary": "rgba(0, 0, 0, 0.54)",
        "disabled": "rgba(0, 0, 0, 0.38)",
        "hint": "rgba(0, 0, 0, 0.38)"
      },
      "divider": "rgba(0, 0, 0, 0.12)",
      "secondary": {
        "main": "#152832",
        "light": "rgb(67, 83, 91)",
        "dark": "rgb(14, 28, 35)",
        "contrastText": "#fff"
      },
      "common": { "black": "#000", "white": "#fff" },
      "error": {
        "light": "#e57373",
        "main": "#f44336",
        "dark": "#d32f2f",
        "contrastText": "#fff"
      },
      "type": "light",
      "action": {
        "hoverOpacity": 0.08,
        "hover": "rgba(0, 0, 0, 0.08)",
        "selected": "rgba(0, 0, 0, 0.14)",
        "disabledBackground": "rgba(0, 0, 0, 0.12)",
        "disabled": "rgba(0, 0, 0, 0.26)",
        "active": "rgba(0, 0, 0, 0.54)"
      },
      "primary": {
        "main": "#00d885",
        "light": "rgb(51, 223, 157)",
        "dark": "rgb(0, 151, 93)",
        "contrastText": "rgba(0, 0, 0, 0.87)"
      }
    },    
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    // overrides: {
    //     MuiButton: { // override the styles of all instances of this component
    //         root: { // Name of the rule
    //             color: 'white', // Some CSS
    //         },
    //     },
    // },
});

export default theme;