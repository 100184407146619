import * as React from "react";
import {
  BooleanField,
  NumberField,
  EmailField,
  ImageField,
  DateField,
  TextField,
  FunctionField,
  ReferenceManyField,
  Datagrid,
  ShowController,
  ShowView,
  TabbedShowLayout,
  Tab,
  Button as AdminButton
} from 'react-admin';
import { JsonField } from "react-admin-json-view";
import { Card as MUCard, CardHeader, CardContent } from "@material-ui/core";
import { timeOptions } from "../constants";
import { Link } from 'react-router-dom';
import UserSeasons from "../components/UserSeasons";
import GameList from "../games/GameList";
import ChatList from "../chats/ChatList"
import PaymentPromoCodeList from "../payments/PaymentPromoCodeList";
import CopyFieldWrapper from "../CopyFieldWrapper";

const Card = (props) => (
  <MUCard style={{ padding: 10, width: "auto" }} {...props} />
);


const UserShow = (props) => (
  <ShowController {...props}>
    {controllerProps => (
      <ShowView {...props} {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="Summary">
            <CopyFieldWrapper record={props.record} label="Id" source="id" />
            <DateField label="Created" source="createdAt" showTime={true} options={timeOptions} locales="en-GB" />
            <ImageField source="avatar" title="Avatar" />
            <TextField source="name" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <CopyFieldWrapper
              record={props.record}
              label="Email"
              source="email"
              ReactAdminComponent={EmailField}
            />
            <TextField source="mobile" />
            <TextField label="Entered Location" source="location.text" />
            <TextField label="Country" source="location.country.name.en_US" />
            <TextField label="City" source="location.city.name.en_US" />
            <TextField label="Place" source="location.place.name.en_US" />
            <NumberField label="Skill" source="skillLevel" />
            <NumberField label="League Entry Skill Level" source="leagueEntrySkillLevel" />
            <NumberField label="Spin Rating" source="spinRating" />
            <FunctionField
              label="Skill From Rating"
              render={record => ((record.spinRating / 750) - 1).toFixed(2)}
            />
            <BooleanField label="League Verified?" source="isLeagueVerified" />
            <FunctionField
              label="Chat Status"
              render={record => (record.newChatsInDay >= 100) ? "Blocked" : "Active"}
            />
            <BooleanField label="Deleted" source="deleted" />
            <BooleanField label="Flagged" source="flagged" />
            <hr />
            <h3>Membership</h3>
            <TextField source="membership.type" label="Membership Type" />
            <DateField source="membership.start" label="Membership Start" showTime={true} options={timeOptions} locales="en-GB" />
            <DateField source="membership.end" label="Membership End" showTime={true} options={timeOptions} locales="en-GB" />
            <TextField source="membership.fee" label="Membership Fee" />
            <hr />
            {/* <TextField label="Version" source="appInfo.version" />
          <TextField label="Device" source="appInfo.deviceModel" />
          <TextField label="Device Name" source="appInfo.deviceName" />
          <TextField label="Push" source="appInfo.pushPermissions" />   */}
            {/* <AvatarField source="avatar" />               */}
            <DateField source="lastOnline" showTime={true} options={timeOptions} locales="en-GB" />
            <BooleanField label="Hidden" source="hidden" />
            <BooleanField label="Boarded" source="boarded" />
            {/* <JsonField source="notificationSettings" /> */}
            <JsonField
              source="appInfo"
              addLabel={true}
              jsonString={false} // Set to true if the value is a string, default: false
              reactJsonOptions={{
                // Props passed to react-json-view
                name: null,
                collapsed: false,
                enableClipboard: false,
                displayDataTypes: false,
              }}
            />
            <JsonField
              source="notificationSettings"
              addLabel={true}
              jsonString={false} // Set to true if the value is a string, default: false
              reactJsonOptions={{
                // Props passed to react-json-view
                name: null,
                collapsed: false,
                enableClipboard: false,
                displayDataTypes: false,
              }}
            />
            <hr />
            <h3>Notes</h3>
            <TextField source="notes" label="User Notes" />
          </Tab>
          <Tab label="Games">
            <GameList source="Game" filter={{ player: props.id }} hidePlayerFilters={true} />
          </Tab>
          <Tab label="Chats">
            <ChatList source="Chat" filter={{ participants: [props.id] }} hidePlayerFilters={true} />
          </Tab>
          <Tab label="Coupons">
            <PaymentPromoCodeList filter={{ userId: props.id, hasPromoCode: true }} source="Payment" />
          </Tab>
          <Tab label="Leagues">
            <h3>Seasons</h3>
            <hr />
            <h5>Current Seasons</h5>
            <UserSeasons filter={{ status: "live" }} />
            <h5>Past Seasons</h5>
            <UserSeasons filter={{ status: "closed" }} />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )
    }
  </ShowController>
);


export default UserShow;
