import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    Filter,
    TextInput
} from 'react-admin';
import SpinPagination from "../components/SpinPagination";
import { timeOptions } from "../constants";
import PlayerFilter from "../components/filters/PlayerFilter";

const CouponFilter = props => (
    <Filter {...props} >
        <TextInput label="Id" source="_id" defaultValue="" />
        <TextInput label="Coupon" source="q" defaultValue="" />
        <PlayerFilter label="User" sourceName="User" source="userId" />
    </Filter>
);

export const CouponList = props => (
    <List {...props} filters={<CouponFilter />} pagination={<SpinPagination />} perPage={50} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField source="userId" reference="User">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="code" />
            <TextField source="discount" />
            <DateField source="createdAt" showTime={true} options={timeOptions} locales="en-GB" />
        </Datagrid>
    </List>
);

export default CouponList;
