import * as React from "react";
import {
  BooleanField,
  NumberField,
  EmailField,
  SimpleForm,
  ImageField,
  DateField,
  Toolbar,
  TextField,
  TextInput,
  Filter,
  Datagrid,
  List,
  useGetList,
  useQuery,
  SelectInput
} from 'react-admin';

import { useFormState } from 'react-final-form';
import { JsonField } from "react-admin-json-view";
import AvatarField from "../AvatarField";
import CustomDataGridBody from "../components/CustomDataGridBody";
import SpinPagination from "../components/SpinPagination";
import { timeOptions } from "../constants";
import PlaceSelect from "../components/filters/PlaceSelect";
import CitySelect from "../components/filters/CitySelect";
import CopyFieldWrapper from "../CopyFieldWrapper";

const UserFilter = props => (
  <Filter {...props}>
    {/* <QuickFilter source="owner" label="League Games" defaultValue={null} />
      <QuickFilter source="hosted" label="Friendly Games" defaultValue={true} />
      <QuickFilter source="full" label="Joined Games" defaultValue={true} />
      <QuickFilter source="full" label="Open Games" defaultValue={false} /> */}
    <TextInput label="Name" source="q" defaultValue="" />
    <TextInput label="Email" source="emailq" defaultValue="" />
    <TextInput label="Mobile" source="mobileq" defaultValue="" />
    <CitySelect
      label="City"
      source="location.cityId"
    />
    <PlaceSelect
      source="location.placeId"
      label="Place"
    />
    <TextInput label="Id" source="_id" defaultValue={null} />
  </Filter>
);

export const UserList = props => (
  <List {...props} filters={<UserFilter />} pagination={<SpinPagination />} perPage={50} sort={{ field: 'createdAt', order: 'DESC' }} exporter={false}>
    <Datagrid rowClick="show" body={<CustomDataGridBody resourceType="user" />}>
      <AvatarField source="avatar" />
      <TextField source="name" />
      <CopyFieldWrapper
        record={props.record}
        source="email"
        ReactAdminComponent={EmailField}
      />
      <DateField label="Created" source="createdAt" showTime={true} options={timeOptions} locales="en-GB" />
      <DateField source="lastOnline" showTime={true} options={timeOptions} locales="en-GB" />
      <TextField label="Location" source="location.text" />
      <NumberField label="Skill" source="skillLevel" />
      {/* <JsonField source="appInfo" /> */}
      {/* <TextField label="Version" source="appInfo.version" />
        <TextField label="Device" source="appInfo.deviceModel" />
        <TextField label="Device Name" source="appInfo.deviceName" /> */}
      <TextField label="Push" source="appInfo.pushPermissions" />
      <BooleanField label="Hidden" source="hidden" />
      <BooleanField label="Boarded" source="boarded" />
      {/* <TextField source="id" /> */}
    </Datagrid>
  </List>
);

export default UserList;
