import * as React from "react";
import { 
    BooleanField,
    ReferenceField,
    FunctionField,
    NumberField,
    ArrayField,
    DateField,
    SingleFieldList,
    TextField,
    ChipField,
    Filter,
    Datagrid,
    List,
    SelectInput,
    TextInput
} from 'react-admin';
import { JsonField } from "react-admin-json-view";
import AvatarField from "../AvatarField";
import QuickFilter from "../components/QuickFilter";
import SpinPagination from "../components/SpinPagination";
import { timeOptions } from "../constants";
import PlayerFilter from "../components/filters/PlayerFilter";

const skillLevels = [
  { id: "beginner", name: "Beginner" },
  { id: "low_improver", name: "Low Improver" },
  { id: "mid_improver", name: "Mid Improver" },
  { id: "up_improver", name: "Up Improver" },
  { id: "low_intermediate", name: "Low Intermediate" },
  { id: "mid_intermediate", name: "Mid Intermediate" },
  { id: "up_intermediate", name: "Up Intermediate" },
  { id: "low_advanced", name: "Low Advanced" },
  { id: "mid_advanced", name: "Mid Advanced" },
  { id: "up_advanced", name: "Up Advanced" },
  { id: "expert", name: "Expert" },
];

const frequencyOptions = [
  { id: "daily", name: "Daily" },
  { id: "weekly", name: "Weekly" },
  { id: "monthly", name: "Monthly" },
  { id: "quarterly", name: "Quarterly" },
];

const experienceLevels = [
  { id: "no", name: "No Experience" },
  { id: "less_year", name: "Less Than a Year" },
  { id: "less_five_years", name: "Less Than Five Years" },
  { id: "over_five_years", name: "Over Five Years" },
  { id: "first_time", name: "First Time" },
];


const ListFilter = props => (
  <Filter {...props}>  
      {/*<TextInput label="Player Id" source="userId" defaultValue={null} />*/}
      <PlayerFilter label="Player" source="userId"/>
      <SelectInput
        source="skillLevel"
        choices={skillLevels}
      />
      <SelectInput
        source="playFrequency"
        choices={frequencyOptions}
      />
      <SelectInput
        source="gap"
        choices={experienceLevels}
      />
  </Filter>
);


export const SkillAssesmentList = props => (
  <List {...props} perPage={50} filters={<ListFilter/>} pagination={<SpinPagination />} sort={{ field: 'createdAt', order: 'DESC' }} exporter={false}>
      <Datagrid rowClick="show">          
          <ReferenceField label="Player" source="userId" reference="User">
            <TextField source="name" />
          </ReferenceField>      
          <TextField label="Skill Level" source="skillLevel" />
          <TextField label="Play Frequency" source="playFrequency" />
          <BooleanField label="Competed?" source="hasCompeted" />
          <TextField label="Gap" source="gap" />
          <BooleanField label="Coaching?" source="coaching" />
          <TextField label="Comments" source="comments" />
          <NumberField label="Score" source="result" />
          <DateField label="Created" source="createdAt" showTime={true} locales="en-GB" />
          <DateField labe="Updated" source="updatedAt" showTime={true} locales="en-GB" />
      </Datagrid>
  </List>
);

  
export default SkillAssesmentList;
  