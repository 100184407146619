import React, { useState } from "react";
import {
    useNotify,
    useRefresh,
    SimpleForm,
    Create,
    Button as AdminButton
} from 'react-admin';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@material-ui/core';
import PlayerFilter from "./components/filters/PlayerFilter";
import { useMutation as useApolloMutation } from '@apollo/client';
import LINK_PAYMENTS_AND_SKILL_FROM_EMAIL from "./graphql/mutations/linkPaymentsAndSkillFromEmailMutation";
import client from "./graphql/client";

const LinkPaymentButton = props => {

    const [showModal, setShowModal] = useState(false);
    const [userId, setUserId] = useState(null);
    const notify = useNotify();
    const refresh = useRefresh();

    const payment = props?.record;
    const { id, userId: paymentUserId, email: webEmail } = payment;

    const handleChange = (value) => {
        setUserId(value)
    };

    const [linkedPayments, { loading: linkedPaymentsLoading }] = useApolloMutation(LINK_PAYMENTS_AND_SKILL_FROM_EMAIL, {
        onCompleted: (data) => {
            setShowModal(false);
            const { email, modifiedPayments } = data?.linkPaymentsAndSkillFromEmail
            notify(`${modifiedPayments} ${modifiedPayments === 1 ? `payment with email "${email}" has` : `payments with email "${email}" have`} been linked to the user`);
            refresh();
        },
        onError: (error) => notify(`Report error: ${error.message}`, 'warning'),
        client,
        variables: {
            userId,
            webEmail
        }
    });


    return (
        <>
            {
                !paymentUserId &&
                <>
                    <AdminButton
                        size="small"
                        label="Link with User"
                        onClick={
                            (event) => {
                                event.preventDefault();
                                setShowModal(true);
                            }
                        }
                    />
                    <Dialog
                        open={showModal}
                        aria-labelledby={`alert-dialog-title-${id}`}
                        aria-describedby={`alert-dialog-description-${id}`}
                    >
                        <DialogTitle id={`alert-dialog-title-${id}`}>Link payments with user</DialogTitle>
                        <DialogContent>
                            <DialogContentText id={`alert-dialog-description-${id}`}>
                                This action will link the payments with the user.
                            </DialogContentText>
                            <SimpleForm id={`payment-form-${id}`} toolbar={false} >
                                <PlayerFilter
                                    fullWidth
                                    onChange={handleChange}
                                    source={`linkedUser-${id}`}
                                />
                            </SimpleForm>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={
                                    () => {
                                        setShowModal(false);
                                    }}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={
                                    () => {
                                        if (!userId) {
                                            notify('Please select a user in order to link the payments')
                                            return;
                                        }
                                        linkedPayments();
                                        setShowModal(false);
                                    }
                                }
                                color="primary"
                                autoFocus
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
        </>
    );
}

export default LinkPaymentButton;