import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    FunctionField,
    EmailField,
    BooleanField,
    Filter,
    TextInput
} from 'react-admin';
import CopyFieldWrapper from "../CopyFieldWrapper";
import SpinPagination from "../components/SpinPagination";
import QuickFilter from "../components/QuickFilter";
import { timeOptions } from "../constants";
import { handleRowClick } from "../libs/handleRowClick";
import { convertCurrencyToSymbol } from "../libs/convertCurrencyToSymbol";
import { paymentPaidDataPipeline } from "../libs/paymentPaidDataPipeline";
import PaymentUser from "../components/payments/PaymentUser";
import LinkPaymentButton from "../LinkPaymentButton";

const PaymentFilter = props => (
    <Filter {...props}>
        <TextInput label="Id" source="_id" />
        <TextInput label="Email" source="email" />
        <QuickFilter label="Payment Completed" source="paymentComplete" defaultValue={true} />
        <QuickFilter label="Unlinked from User" source="unlinkedFromUser" defaultValue={true} />
    </Filter>
);

const PaymentList = props => (
    <List {...props} filters={<PaymentFilter />} pagination={<SpinPagination />} perPage={50} exporter={false}>
        <Datagrid isRowSelectable={() => false} rowClick={false}>
            <TextField source="id" />
            <PaymentUser label="Name" {...props} />
            <CopyFieldWrapper
                record={props.record}
                source="email"
                ReactAdminComponent={EmailField}
            />
            <TextField source="source" />
            <TextField source="coupon.code" label="Promo Code" />
            <FunctionField
                label="Amount Paid"
                render={
                    record => {
                        const payment = paymentPaidDataPipeline(record);
                        const paidData = payment?.paidData;
                        const { amount, currency } = paidData?.data?.object ?? {};
                        const formattedAmount = amount ? (amount / 100).toFixed(2) : '0.00';
                        return `${convertCurrencyToSymbol(currency)} ${formattedAmount}`;
                    }
                }
            />
            <FunctionField
                label="Discount"
                render={
                    record => {
                        const discount = record?.coupon?.discount;
                        const payment = paymentPaidDataPipeline(record);
                        const paidData = payment?.paidData;
                        const { currency } = paidData?.data?.object ?? {};
                        const formattedDiscount = discount ? discount.toFixed(2) : '0.00';
                        return `${convertCurrencyToSymbol(currency)} ${formattedDiscount}`;
                    }
                }
            />
            <BooleanField label="Payment Complete" source="paymentComplete" />
            <DateField source="createdAt" label="Created At" showTime={true} options={timeOptions} locales="en-GB" />
            <LinkPaymentButton />
        </Datagrid>
    </List>
);

export default PaymentList;