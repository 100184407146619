import { TypeKind } from 'graphql';
import gql from 'graphql-tag';
import { print } from 'graphql/language/printer'
import {
    CREATE,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    UPDATE,
    DELETE,
} from 'react-admin';
import inflection from 'inflection';
// import _ from 'underscore';
const whiteListedFields = {
    'Court': ['location', 'indoorCourts', 'open', 'outdoorCourts'],
    'User': ['notificationSettings', 'membership'],
    'Area': ['name', 'geometry']
}

const blackListedLocationFields = ['placeId','place','cityId','city','stateId','state','countryId','country'];

const excludedFields = {
    'Game': ['season'],
    'League': ['players'],
    'Season': ['users', 'divisions']
}

const includedFieldsPerAorFetchType = {
    'Game': {
        'GET_LIST': [
            'owner',
            'users',
            'location',
            'hidden',
            'full',
            'skillLevel',
            'gender',
            'flexibleDates',
            'source',
            'createdAt',
            'startTime',
            'status',
            'courtNo',
            'booked',
            'score',
        ]
    },
    'Chat': {
        'GET_LIST': [
            'playerIds',
            'lastMessage',
            'name',
            'topic',
            'createdAt',
            'updatedAt',
            'seasonsPlayed'
        ]
    }
}

const includedResourceFieldsPerOriginalResource = {
    'Game': {
        'User': [
            'avatar',
            '_id',
            'name'
        ]
    },
    'Chat': {
        'ChatMessage': [
            'body'
        ]
    }
}

const cleanTypesFromRecord = (record) => {
    if(!record) {
        return record;
    }
    if(Array.isArray(record)) {
        record.map(s => {
        if(typeof s === 'object' || Array.isArray(s)) {
             return cleanTypesFromRecord(s);
        }
        return s;
    })
    } else if(typeof record === 'object') {
        Object.keys(record).forEach(s => {
            if(s === '__typename') {
                delete record[s];
            }
        if(typeof record[s] === 'object' || Array.isArray(record[s])) {
            record[s] = cleanTypesFromRecord(record[s]);
        }
    })
    }
    
    return record;
}
export const isSubObject = field => {
    return (
        field.type.kind === TypeKind.OBJECT ||
        (field.type.kind === TypeKind.LIST &&
            (field.type.ofType.kind === TypeKind.OBJECT ||
                (field.type.ofType.kind === TypeKind.LIST && field.type.ofType.ofType.kind !== TypeKind.SCALAR))) ||
        (field.type.kind === TypeKind.NON_NULL &&
            (field.type.ofType.kind === TypeKind.OBJECT ||
                field.type.ofType.kind === TypeKind.LIST)) ||
        ((field.type.ofType && field.type.ofType.kind) === TypeKind.NON_NULL &&
            ((field.type.ofType &&
                field.type.ofType.ofType &&
                field.type.ofType.ofType.kind) === TypeKind.LIST ||
                (field.type.ofType &&
                    field.type.ofType.ofType &&
                    field.type.ofType.ofType.kind) === TypeKind.OBJECT))
    );
};

const getType = field => {
    if (
        field.type.kind === TypeKind.LIST ||
        field.type.kind === TypeKind.NON_NULL
    ) {
        return field.type.ofType;
    }
    return field.type;
};

const isResource = (field, resources) => {
    const type = getType(field);
    return resources.some(r => r.type.name === type.name);
};

const buildFieldList = (
    introspectionResults,
    resource,
    aorFetchType,
    depth = 0,
    originalResource
) => {
    const types = introspectionResults.types;
    const resources = introspectionResults.resources;
    if (!resource.type.fields) return;
    return resource.type.fields
        .filter(field => !field.name.startsWith('__') 
            && !(field.name === 'geometry' && originalResource && originalResource.type.name !== 'GeographicArea')
            && !((field.name === 'seasonClientSecret' || 
            field.name === 'annualClientSecret' ||
            field.name === 'annualPaymentIntent' ||
            field.name === 'seasonPaymentIntent'))
        )
        .filter(field =>  {
            let includedResourceFieldPerOriginalResource = true;
            const includeField = excludedFields[resource.type.name] ? !excludedFields[resource.type.name].includes(field.name) : true;
            const includedFieldPerAorFetchType = 
                includedFieldsPerAorFetchType.hasOwnProperty(resource.type.name) &&
                includedFieldsPerAorFetchType[resource.type.name].hasOwnProperty(aorFetchType) ?
                    includedFieldsPerAorFetchType[resource.type.name][aorFetchType].includes(field.name) :
                    true;
            if (originalResource.type.name !== resource.type.name) {
                includedResourceFieldPerOriginalResource = 
                    includedResourceFieldsPerOriginalResource.hasOwnProperty(originalResource.type.name) &&
                    includedResourceFieldsPerOriginalResource[originalResource.type.name].hasOwnProperty(resource.type.name) ?
                        includedResourceFieldsPerOriginalResource[originalResource.type.name][resource.type.name].includes(field.name) :
                        true;
            }
            return includeField && includedFieldPerAorFetchType && includedResourceFieldPerOriginalResource;
        })
        .map(field => {
            try {
                if (isSubObject(field, types) && depth < 8) {
                    let typeToCheck = getType(field);
                    if (isResource(field, resources)) {
                        const type = types.find(
                            t => t.name === typeToCheck.name
                        );
                        const resource = introspectionResults.resources.find(
                            r => r.type.name === type.name
                        );
                        if (
                            type &&
                            resource &&
                            !(field.type.name === originalResource.type.name)
                        ) {
                            const subFields = buildFieldList(
                                introspectionResults,
                                resource,
                                aorFetchType,
                                depth + 1,
                                originalResource
                            );
                            return `${field.name} { ${subFields} }`;
                        }
                    } else {
                        return buildNonResourceFields(field, types, typeToCheck, resources, introspectionResults, aorFetchType, depth, originalResource);
                    }
                    return false;
                }
                // if (field.name === '_id' && depth >= 1) {
                //     return false;
                // } else {
                    return field.name;
                // }
            } catch (err) {
                console.log(err);
            }
            return false;
        })
        .filter(f => f !== false)
        .join(' ');
};

const buildNonResourceFields = (field, types, typeToCheck, resources, introspectionResults, aorFetchType, depth, originalResource) => {
    const type = types.find(
        t => t.name === typeToCheck.name
    )                        
    if (type && type.fields) {
        // eslint-disable-next-line
        let subFields = type.fields
            .map(_type => {
                if (!isSubObject(_type, types)) {
                    return _type.name;
                } else {
                    if (isResource(_type, resources)) {
                        const resource = introspectionResults.resources.find(
                            r =>
                                r.type.name ===
                                _type.type.name
                        );
                        if (_type && resource) {
                            const subFields = buildFieldList(
                                introspectionResults,
                                resource,
                                aorFetchType,
                                depth + 1,
                                originalResource
                            );
                            return `${
                                _type.name
                            } { ${subFields} }`;
                        }
                    } else {
                        return buildNonResourceFields(_type, types, getType(_type), resources, introspectionResults, aorFetchType, depth, originalResource);                                             
                    }
                }
            })
            .filter(item => item);
        // subFields = _.without(subFields, '_id');
        subFields = subFields.filter(sf => sf !== '_id')
        if (subFields.length >= 1) {
            return `${field.name} { ${subFields} }`;
        } else {
            return false;
        }
    }
}

const buildUpdatedRecord = (resource, data, previousData) => {
    const updatedData = {};
    const resourceType = data.__typename;
    cleanTypesFromRecord(data)
    Object.keys(data).forEach(key => {
        //&& !resource.field.name.startsWith('__')
        // if (key === 'users') {
        //     console.log(" type of ", data[key], typeof data[key]);
        //     console.log(" is equal to  ", data[key] !== previousData[key]);    
        // }
        if (!previousData || (whiteListedFields[resourceType] && whiteListedFields[resourceType].includes(key)) || ((typeof data[key] !== 'object' || (Array.isArray(data[key]) && typeof data[key][0] === 'string')) && data[key] !== previousData[key])) {
            if (key === 'location' && data[key] !== null && typeof data[key] === 'object') {
                console.log(data[key]);
                const locationKeys = Object.keys(data[key]);
                locationKeys.forEach(locationKey => {
                    if(blackListedLocationFields.includes(locationKey)){
                        delete data[key][locationKey];
                    }
                });
            }
            updatedData[key] = data[key];
        }
    })
    console.log("updated record", updatedData);
    return updatedData;
}

const isArrayEqual = (a, b) => {
    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }    
}

export const buildQuery = introspectionResults => (
    aorFetchType,
    resourceName,
    params
) => {
    console.log('aorFetchType', aorFetchType);
    console.log('params', params);
    console.log('introspectionResults', introspectionResults);
    console.log('resourceName', resourceName);
    const resource = introspectionResults.resources.find(
        r => r.type.name === resourceName
    );
    console.log('resource', resource);
    console.log('aorFetchType', aorFetchType);
    var result = {};
    switch (aorFetchType) {
        case 'GET_MANY':
          if (params.ids) {
            result = {
                query: gql`query ${inflection.camelize(
                    resourceName,
                    true
                )}sById ($ids: [MongoID!]!) {
            data: ${inflection.camelize(
                resourceName,
                true
            )}sById (_ids: $ids, limit: 10000) {
              id: _id,              
              ${buildFieldList(
                  introspectionResults,
                  resource,
                  aorFetchType,
                  0,
                  resource
              )}
            }
          }`,
                variables: params,
                parseResponse: response => ({
                    data: response.data.data,
                }),
            };
          } else {
            params.page = params.pagination.page;
            params.perPage = params.pagination.perPage;
            if (params.target) {
                params.filter[params.target] = params.id;
            }
            if (params.sort && params.sort.field) {
                if (params.sort.field === 'id') params.sort.field = '_id';
                params.sort = `${params.sort.field.toUpperCase()}_${params.sort.order.toUpperCase()}`;
            }
            result = {
                query: gql`query ${inflection.camelize(
                    resource[aorFetchType].name,
                    true
                )}($filter: FilterFindMany${resourceName}Input, $sort: SortFindMany${resourceName}Input, $page: Int, $perPage: Int) {
            data: ${inflection.camelize(
                resource[aorFetchType].name,
                true
            )}(filter: $filter, sort: $sort, page: $page, perPage: $perPage) {
              items {
                id: _id
                ${buildFieldList(
                    introspectionResults,
                    resource,
                    aorFetchType,
                    0,
                    resource
                )}
              }
              total: count
            }
          }`,
                variables: params,
                parseResponse: response => ({
                    data: response.data.data.items,
                    total: response.data.data.total,
                }),
            };            
          }
          break;
        case 'GET_LIST':
            params.page = params.pagination.page;
            params.perPage = params.pagination.perPage;
            if (params.target) {
                params.filter[params.target] = params.id;
            }
            if (params.sort && params.sort.field) {
                if (params.sort.field === 'id') params.sort.field = '_id';
                params.sort = `${params.sort.field.toUpperCase()}_${params.sort.order.toUpperCase()}`;
            }
            result = {
                query: gql`query ${inflection.camelize(
                    resource[aorFetchType].name,
                    true
                )}($filter: FilterFindMany${resourceName}Input, $sort: SortFindMany${resourceName}Input, $page: Int, $perPage: Int) {
            data: ${inflection.camelize(
                resource[aorFetchType].name,
                true
            )}(filter: $filter, sort: $sort, page: $page, perPage: $perPage) {
              items {
                id: _id
                ${buildFieldList(
                    introspectionResults,
                    resource,
                    aorFetchType,
                    0,
                    resource
                )}
              }
              total: count
            }
          }`,
                variables: params,
                parseResponse: response => ({
                    data: response.data.data.items,
                    total: response.data.data.total,
                }),
            };            
            break;
        case 'GET_MANY_REFERENCE':
            params.page = params.pagination.page;
            params.perPage = params.pagination.perPage;
            if (params.target) {
                params.filter[params.target] = params.id;
            }
            if (params.sort && params.sort.field) {
                if (params.sort.field === 'id') params.sort.field = '_id';
                params.sort = `${params.sort.field.toUpperCase()}_${params.sort.order.toUpperCase()}`;
            }
            result = {
                query: gql`query ${inflection.camelize(
                    resource[aorFetchType].name,
                    true
                )}($filter: FilterFindMany${resourceName}Input, $sort: SortFindMany${resourceName}Input) {
            data: ${inflection.camelize(
                resource[aorFetchType].name,
                true
            )}(filter: $filter, sort: $sort, limit: 10000) {
                id: _id
                ${buildFieldList(
                    introspectionResults,
                    resource,
                    aorFetchType,
                    0,
                    resource
                )}
            }
          }`,
                variables: params,
                parseResponse: response => ({
                    data: response.data.data,
                    // total: response.data.data.total,
                }),
            };            
            break;
        case 'GET_ONE':
            result = {
                query: gql`query ${resource[aorFetchType].name}($id: MongoID!) {
            data: ${resource[aorFetchType].name}(_id: $id) {
              ${buildFieldList(
                  introspectionResults,
                  resource,
                  aorFetchType,
                  0,
                  resource
              )}
              id: _id
            }
          }`,
                variables: { id: params.id },
                parseResponse: response => ({
                    data: response.data.data,
                    id: response.data.data.id,
                }),
            };
            break;
        case 'UPDATE':
            result = {
                query: gql`mutation ${resource[aorFetchType].name}($id: MongoID!, $record: UpdateById${resourceName}Input! ) {
            data: ${resource[aorFetchType].name}(_id: $id, record: $record) {
                record {
                    ${buildFieldList(
                        introspectionResults,
                        resource,
                        aorFetchType,
                        0,
                        resource
                    )}
                    id: _id      
                }
            }
          }`,
                variables: { id: params.id, record: buildUpdatedRecord(resource, params.data, params.previousData) },
                parseResponse: response => ({
                    data: response.data.data,
                    id: response.data.data.record.id,
                }),
            };
            break;    
        case 'CREATE':
            result = {
                query: gql`mutation ${resource[aorFetchType].name}($record: CreateOne${resourceName}Input! ) {
                    data: ${resource[aorFetchType].name}(record: $record) {
                        record {
                            ${buildFieldList(
                                introspectionResults,
                                resource,
                                aorFetchType,
                                0,
                                resource
                            )}
                            id: _id      
                        }
                    }
                }`,
                variables: { record: params.data },
                parseResponse: response => ({
                    data: response.data.data.record,
                    id: response.data.data.record.id,
                }),
            };
            break;                
        default:
            return undefined;
    }
    console.log(result);
    console.log(print(result.query))
    return result;
};
