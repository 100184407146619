import React from "react";
import { useFormState } from 'react-final-form';
import { SelectInput, useQuery } from "react-admin";

const CitySelect = props => {
    const { data: cityData, loading: cityFilterLoading, error: cityFilterError } = useQuery({ 
        type: 'getList',
        resource: 'GeographicArea',
        payload: { filter: { type: 'CITY' }, pagination: { page: 1, perPage: 50 } }
    });  
  
    return (
        <SelectInput
            choices={cityData}
            isLoading={cityFilterLoading}
            {...props}
            optionText="name.en_GB"
            optionValue="_id"
        />        
    )
  }

  export default CitySelect;