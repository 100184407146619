import * as React from "react";
import {
  SimpleForm,
  TextInput,
  Create,
  NumberInput,
  required,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput
} from "react-admin";
import { Card as MUCard } from "@material-ui/core";
import LocationPickerInput from "../components/GoogleMaps/LocationPickerInput";
import { accessChoices, bookingChoices, courtChoices } from "../constants";

const Card = (props) => (
  <MUCard style={{ padding: 10, width: "auto" }} {...props} />
);
const locationFields = [
  { source: "addressLine1", label: "Address Line 1", required: true },
  { source: "addressLine2", label: "Address Line 2" },
  { source: "addressLine3", label: "Address Line 3" },
  { source: "postcode", label: "Post Code", required: true },
];
const defaultValues = {
  indoorCourts: [
    { label: "Hard", count: 0 },
    { label: "Carpet", count: 0 },
  ],
  outdoorCourts: [
    { label: "Hard", count: 0 },
    { label: "Astro", count: 0 },
    { label: "Clay", count: 0 },
    { label: "Grass", count: 0 },
  ],
  open: [
    { label: "MON", hours: '' },
    { label: "TUE", hours: '' },
    { label: "WED", hours: '' },
    { label: "THU", hours: '' },
    { label: "FRI", hours: '' },
    { label: "SAT", hours: '' },
    { label: "SUN", hours: '' },
  ],
};
export const CourtCreate = (props) => (
  <Create {...props}>
    <SimpleForm initialValues={defaultValues}>
      <TextInput label="Name" source="name" validate={required()}/>
      <TextInput label="Booking Link" source="bookingLink" />
      <TextInput source="phone" />
      <LocationPickerInput
        source="location"
        label="Location"
        extraFields={locationFields}
      />
      <Card variant="outlined">
        <ArrayInput source="open" label="Open">
          <SimpleFormIterator>
            <TextInput source="label" label="label" />
            <TextInput source="hours" label="hours" />
          </SimpleFormIterator>
        </ArrayInput>
      </Card>
      <SelectInput source="courtType" choices={courtChoices} />
      <BooleanInput source="privateCourt" label="Private" defaultValue={false}/>
      <SelectInput source="access" choices={accessChoices} />
      <SelectInput source="bookingType" choices={bookingChoices} />
      <BooleanInput source="lights" defaultValue={false}/>
      <Card variant="outlined">
        <ArrayInput source="indoorCourts">
          <SimpleFormIterator>
            <TextInput source="label" label="label" />
            <NumberInput source="count" label="count" />
          </SimpleFormIterator>
        </ArrayInput>
      </Card>
      <Card variant="outlined">
        <ArrayInput source="outdoorCourts">
          <SimpleFormIterator>
            <TextInput source="label" label="label" />
            <NumberInput source="count" label="count" />
          </SimpleFormIterator>
        </ArrayInput>
      </Card>
      <TextInput source="indoorPrice" />
      <TextInput source="outdoorPrice" />
      <TextInput source="membershipPrice" />
      <TextInput source="info" multiline rows={5} style={{width: 500}}/>
      <TextInput source="internalNotes" multiline rows={5} style={{width: 500}}/>

      {/* <ReferenceInput label="User" source="userId" reference="User"> */}
      {/* <AutocompleteInput optionText="name" /> */}
      {/* </ReferenceInput>                     */}
    </SimpleForm>
  </Create>
);

export default CourtCreate;
