import * as React from "react";
import { Link } from 'react-router-dom';
import SpinPagination from "../components/SpinPagination";
import {
    TextField,
    Filter,
    Datagrid,
    List,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    FunctionField,
    DateField,
    TextInput,
    DateTimeInput
} from 'react-admin';
import PlayerFilter from "../components/filters/PlayerFilter";
import PlayersRelationsFilter from "../components/filters/PlayersRelationsFilter";

const ChatMessageFilter = props => (
    <Filter {...props}>
        <PlayerFilter label="Sender" sourceName="Sender" source="sender" />
        <PlayersRelationsFilter label="Recipients" source="recipientsIn" />
        <TextInput label="Text Search" source="textSearch" defaultValue="" />
        <DateTimeInput label="From Date" source="from" />
        <DateTimeInput label="To Date" source="to" />
    </Filter>
);

const ChatMessageList = props => (
    <List {...props} filters={<ChatMessageFilter />} pagination={<SpinPagination />} perPage={50} sort={{ field: 'createdAt', order: 'DESC' }} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="_id" />
            <DateField source="createdAt" showTime={true} />
            <ReferenceField source="sender" reference="User">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceArrayField source="recipients" reference="User">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="body" />
            <FunctionField
                label="Chat"
                render={record => (
                    <Link
                        to={`/Chat/${record?.chatId}/show#${record?._id}`}
                        target="_blank"
                        style={
                            {
                                textDecoration: 'none',
                                color: '#00d885'
                            }
                        }
                    >
                        {record?.chatId}
                    </Link>
                )}
            />
        </Datagrid>
    </List>
);

export default ChatMessageList;