import * as React from "react";
import { 
    AutocompleteArrayInput,
    ReferenceArrayInput,
    TextInput,
    Edit,
    TabbedForm,
    FormTab,
    DateTimeInput,
    SimpleFormIterator,
    ArrayInput
   } from 'react-admin';

const dateParser = v => {
    // v is a string of "YYYY-MM-DD" format
    // const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
    // if (match === null) return;
    // const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
    // if (isNaN(d)) return;
    console.log(v);
    return v;
};
  
const SeasonEdit = (props) => (  
    <Edit {...props}>
        <TabbedForm>
          <FormTab label="overview">
            <TextInput disabled label="Id" source="id" />    
            {/* <TextInput source="name" /> */}
            <TextInput source="name" />
            <TextInput source="price" />          
            <TextInput source="annualPrice" />        
            <TextInput source="info" multiline fullWidth/>                 
            <TextInput source="rules" multiline fullWidth/>
            <TextInput source="joinAlert" multiline fullWidth/>
            <TextInput source="slug" />
            <DateTimeInput source="regStart" parse={dateParser} options={{ timezone: 'UTC' }}/>
            <DateTimeInput source="regEnd" parse={dateParser} />                          
            <DateTimeInput source="start" parse={dateParser} options={{ timezone: 'UTC' }}/>
            <DateTimeInput source="midPoint" parse={dateParser} />                        
            <DateTimeInput source="end" parse={dateParser} />          
            {/* <TextField label="Location" source="location.text" />
            <NumberInput label="Skill" source="skillLevel" />
            <NumberInput label="League Entry Skill" source="leagueEntrySkillLevel" />
            <TextInput disabled label="Version" source="appInfo.version" />
            <TextInput disabled label="Device" source="appInfo.deviceModel" />
            <TextInput disabled label="Device Name" source="appInfo.deviceName" />
            <TextInput disabled label="Push" source="appInfo.pushPermissions" />           */}
          </FormTab>
          <FormTab label='Players'>
            <ReferenceArrayInput label="Players" source="users" reference="User">
              <AutocompleteArrayInput/>
            </ReferenceArrayInput>          
          </FormTab>
          <FormTab label="divisions">
            <ArrayInput source="divisions">
              <SimpleFormIterator>
                  <TextInput source="name" label="name"/>
                  {/* <FormDataConsumer>
                      {({ getSource, scopedFormData }) => {
                          return (
                            // <ArrayInput source={getSource('players')}>
                            //   <SimpleFormIterator>
                            //       <TextInput source="playerId" label="playerId"/>
                            //       <NumberInput source="score.played" label="played"/>
                            //       <NumberInput source="score.wins" label="wins"/>
                            //       <NumberInput source="score.loses" label="losses"/>
                            //       <NumberInput source="score.notFinished" label="Not Finished"/>
                            //       <NumberInput source="score.bonusPoints" label="Bonus Points"/>
                            //       <NumberInput source="score.points" label="Total Points"/>
                            //   </SimpleFormIterator>
                            // </ArrayInput>
                            // <ReferenceArrayInput label="Players" source={getSource('players')} reference="User">
                            //   <AutocompleteArrayInput source="playerId"/>
                            // </ReferenceArrayInput>                                                     
                          );
                      }}
                  </FormDataConsumer> */}
              </SimpleFormIterator>          
            </ArrayInput>
          </FormTab>
        </TabbedForm>
    </Edit>  
  )

  export default SeasonEdit;
  