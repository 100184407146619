import * as React from "react";
import { useMutation, useNotify, useRedirect, Button } from 'react-admin';

const CreateAdminEventLogButton = ({ record }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [approve, { loading }] = useMutation(
        {
            type: "create",
            resource: 'EventLog',
            payload: { data: { playerIds: record.playerIds, source: "admin", type: "game_played" } },
        },
        {
            onSuccess: ({ data }) => {
                redirect(`/eventlog/${data.id}`);
                notify('Succesfully Reported');
            },
            onFailure: (error) => notify(`Report error: ${error.message}`, 'warning'),
        }
    );
    return <Button size="large" label="Add Played Game Event" onClick={approve} disabled={loading} />;
};

export default CreateAdminEventLogButton;