import React from "react";
import { useFormState } from 'react-final-form';
import { SelectInput, useQuery } from "react-admin";

const PlaceSelect = props => {
    
    const { values } = useFormState();

    const { data: placeData, loading: cityFilterLoading, error: cityFilterError } = useQuery({ 
      type: 'getList',
      resource: 'GeographicArea',
      payload: { filter: { parent: values?.location?.cityId, type: 'PLACE' }, pagination: { page: 1, perPage: 50 } }
    });  
  
    return (
        <SelectInput
            choices={placeData}
            isLoading={cityFilterLoading}
            {...props}
            optionText="name.en_GB"
            optionValue="_id"
        />        
    )
  }

  export default PlaceSelect;