import React from "react";
import {
  ReferenceArrayInput,
  AutocompleteArrayInput
} from "react-admin";
import { useFormState } from 'react-final-form';
import PlayerOption, {
  playerInputText,
  playerMatchSuggestion,
  nameFilterToQuery
} from "./PlayerFilterOptions";

const PlayersRelationsFilter = (props) => {

  const { values } = useFormState();
  
  return (
    <ReferenceArrayInput reference="User" filterToQuery={nameFilterToQuery} {...props} filter={{exceptFor: values.player}}>
      <AutocompleteArrayInput
        optionText={(choice) => <PlayerOption record={choice} />}
        inputText={playerInputText}
        matchSuggestion={playerMatchSuggestion}
      />
    </ReferenceArrayInput>
  );
};

export default PlayersRelationsFilter;