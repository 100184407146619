// import PeopleIcon from '@material-ui/icons/People';
// import SeasonCreate from './UserCreate';
// import UserEdit from './UserEdit';
import GameList from './GameList';
import GameShow from './GameShow';
import GameEdit from './GameEdit';
// import UserList from './UserList';
// import SeasonShow from './UserShow';

export default {
    list: GameList,
    // create: UserCreate,
    edit: GameEdit,
    show: GameShow,
    // icon: PeopleIcon,
};