import * as React from "react";
import {
  NumberField,
  TextField,
  SimpleShowLayout,
  Show,
  ReferenceField,
  FunctionField,
} from "react-admin";
import { JsonField } from "react-admin-json-view";
import {
  Card as MUCard,
  CardHeader,
  CardContent,
  Button,
} from "@material-ui/core";
import { gql, useLazyQuery as useApolloLazyQuery } from "@apollo/client";
import MapGeoJSON from "../components/GoogleMaps/MapGeoJSON";
import GEOGRAPHIC_AREA_CHILDREN from "../graphql/geographicAreaChildrenQuery";
import client from "../graphql/client";

const Card = (props) => (
  <MUCard style={{ padding: 10, width: "auto" }} {...props} />
);

const UserShow = (props) => {
  console.log("prop>>>", props);
  const [features, setFeatures] = React.useState([]);
  const [getGeographicAreaChildren] = useApolloLazyQuery(
    GEOGRAPHIC_AREA_CHILDREN,
    {
      onCompleted: (data) => {
        console.log("data", data);
        setFeatures([
          ...features,
          ...data.geographicAreaChildren.map((s) => ({
            type: "Feature",
            geometry: s.geometry,
          })),
        ]);
        console.log(
          "setting features",
          data.geographicAreaChildren.map((s) => ({
            type: "Feature",
            geometry: s.geometry,
          }))
        );
        // if (data.commentsInVideo) {
        //   setComments(data.commentsInVideo);
        // } else {
        //   setComments([]);
        // }
      },
      // variables: {
      //   id:
      // }
      onError: (err) => {
        console.error("err", err);
        // setComments([]);
        // setComments(data.commentsInVideo);
      },
      client,
      fetchPolicy: "network-only",
    }
  );
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="type" />
        {/* <NumberField source="level"/> */}
        <ReferenceField
          label="Parent"
          source="parent"
          reference="GeographicArea"
        >
          <FunctionField
            render={(record) => {
              console.log("parent is", record);
              return (
                <div>
                  {record.name.en_GB} ({record.slug})
                  {record.geometry && (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        console.log("clicked");
                        setFeatures([
                          ...features,
                          {
                            type: "Feature",
                            geometry: record.geometry,
                          },
                        ]);
                      }}
                    >
                      Add to map
                    </Button>
                  )}
                </div>
              );
            }}
          />
        </ReferenceField>
        <TextField source="languageTag" />
        <TextField source="slug" />
        <JsonField source="name" />
        <FunctionField
          label="Geometry"
          style={{ width: "100px" }}
          render={(record) => (
            <Card style={{ width: "calc(100vw - 300px)" }}>
              <Button
                onClick={() => {
                  getGeographicAreaChildren({
                    variables: {
                      id: record._id,
                    },
                  });
                }}
                color="primary"
              >
                Show Children
              </Button>
              <Button
                onClick={() => {
                  setFeatures([]);
                }}
                color="primary"
              >
                Clear Extra geojson
              </Button>
              {record.geometry && (<MapGeoJSON
                source="location"
                label="Location"
                extraFields={[]}
                geoJsonData={
                  features
                    ? {
                        type: "FeatureCollection",
                        features: [
                          {
                            type: "Feature",
                            geometry: {
                              type: record.geometry.type,
                              coordinates: record.geometry.coordinates,
                            },
                          },
                          ...features,
                        ],
                      }
                    : {
                        type: "Feature",
                        geometry: {
                          type: record.geometry.type,
                          coordinates: record.geometry.coordinates,
                        },
                      }
                }
              />)}
            </Card>
          )}
        />
        <FunctionField
          label="Metrics"
          render={record => <a target="_blank" href={`https://dashboard.spintennisapp.com/dashboard/39-friendly-games-compact?${record.type === 'CITY' ? 'city_id' : 'place_id'}=${record.id}`}>View Metrics</a>}
        />        
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
